import { NavigationBar } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';
import DisabledWidgetBody from '@sbrc/components/visualizer-view-shared/widget-panel/DisabledWidgetBody';

interface GripperErrorWidgetProps {
  implementation: Integrations.IntegrationFrontend;
  message: string;
  additionalControls?: React.ReactNode;
}

export default function GripperErrorWidget({
  implementation,
  message,
  additionalControls,
}: GripperErrorWidgetProps) {
  return (
    <WidgetView>
      <NavigationBar>{implementation.getDisplayName()}</NavigationBar>
      {additionalControls}
      <DisabledWidgetBody contentHeader="Error" content={message} />
    </WidgetView>
  );
}
