import { useId } from 'react';

import { SettingsGroupItem, Switch } from '@sb/design-system';

interface FirmwareVisibilityToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export function FirmwareVisibilityToggle({
  checked,
  onChange,
}: FirmwareVisibilityToggleProps) {
  const firmwareSwitchId = useId();

  return (
    <SettingsGroupItem className="tw-px-0" isSeparated>
      <label htmlFor={firmwareSwitchId} className="tw-flex-1">
        Advanced firmware settings
      </label>
      <Switch
        id={firmwareSwitchId}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
    </SettingsGroupItem>
  );
}
