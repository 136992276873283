import cx from 'classnames';
import { useEffect, useState } from 'react';

import {
  Button,
  Icon,
  Modal,
  NavigationBar,
  NavigationBarButton,
} from '@sb/design-system';
import { wait } from '@sb/utilities';
import { useIsRobotConnected } from '@sbrc/hooks';
import { executeHostCommand } from '@sbrc/services';

interface FactoryResetModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FactoryResetModal = ({
  isOpen,
  onClose,
}: FactoryResetModalProps) => {
  const [
    isDataLossAcknowledgementConfirmed,
    setIsDataLossAcknowledgementConfirmed,
  ] = useState(false);

  const [shouldListenForRobotConnection, setShouldListenForRobotConnection] =
    useState(false);

  const isRobotConnected = useIsRobotConnected({});

  useEffect(() => {
    if (shouldListenForRobotConnection) {
      if (isRobotConnected) {
        window.location.reload();
      } else {
        // wait for connection; force reload if no connection
        setTimeout(() => window.location.reload(), 100_000);
      }
    }
  }, [isRobotConnected, shouldListenForRobotConnection]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen onClose={onClose}>
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Done</NavigationBarButton>
        }
      >
        Factory Reset
      </NavigationBar>

      <div
        className={cx(
          'tw-px-24',
          'tw-pb-24',
          'tw-w-512',
          'tw-flex',
          'tw-flex-col',
          'tw-gap-24',
        )}
      >
        <p>
          Running a factory reset will erase all data on the robot, including
          all programmed routines and equipment configuration. This data will
          not be recoverable after deletion.
        </p>

        <label className={cx('tw-flex', 'tw-items-center', 'tw-gap-16')}>
          <div
            className={cx(
              'tw-relative',
              'tw-flex-none',
              'tw-w-24',
              'tw-h-24',
              'tw-bg-fill-primary',
              'tw-rounded-6',
              'tw-border',
              isDataLossAcknowledgementConfirmed
                ? 'tw-border-orange'
                : 'tw-border-label-quaternary',
              'tw-text-orange',
              'tw-flex',
              'tw-items-center',
              'tw-justify-center',
            )}
          >
            <input
              type="checkbox"
              checked={isDataLossAcknowledgementConfirmed}
              disabled={shouldListenForRobotConnection}
              onChange={(e) =>
                setIsDataLossAcknowledgementConfirmed(e.target.checked)
              }
              className={cx(
                'tw-appearance-none',
                'tw-opacity-0',
                'tw-absolute',
              )}
            />
            {isDataLossAcknowledgementConfirmed && (
              <Icon kind="checkmark" className="tw-icon-22" />
            )}
          </div>

          <span className="tw-text-15">I acknowledge loss of data</span>
        </label>

        <Button
          variant="Filled"
          className="tw-rounded-6"
          onClick={async () => {
            executeHostCommand({ command: 'factoryReset' });

            // wait a couple seconds for services to restart, then start listening for robot connection
            await wait(2000);

            setShouldListenForRobotConnection(true);
          }}
          disabled={
            !isDataLossAcknowledgementConfirmed ||
            shouldListenForRobotConnection
          }
        >
          Reset to factory settings
        </Button>
      </div>
    </Modal>
  );
};
