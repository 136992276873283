import cx from 'classnames';

import { SettingsGroup, SettingsGroupItem } from '@sb/design-system';
import type { JointUpdateStatus } from '@sbrc/hooks';

import { FirmwareStatusIcon } from './FirmwareStatusIcon';

type FirmwareJointStatusProps = {
  jointUpdateStatusExcludingIO: JointUpdateStatus[];
};

export function FirmwareJointStatus({
  jointUpdateStatusExcludingIO,
}: FirmwareJointStatusProps) {
  return (
    <div>
      <SettingsGroup className="tw-mb-18 tw-overflow-hidden">
        {jointUpdateStatusExcludingIO.map(
          ({ jointNumber, jointName, status }) => (
            <SettingsGroupItem className={cx('tw-py-8')} key={jointNumber}>
              <div
                className={cx(
                  'tw-flex-1',
                  'tw-flex',
                  'tw-items-center',
                  'tw-gap-8',
                )}
              >
                {jointName}
              </div>

              <FirmwareStatusIcon status={status} />
            </SettingsGroupItem>
          ),
        )}
      </SettingsGroup>
    </div>
  );
}
