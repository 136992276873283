import cx from 'classnames';

import { NavigationBar, NavigationBarButton } from '@sb/design-system';
import { Loader } from '@sb/ui/components';

interface LoadingStateProps {
  onClose: () => void;
}

export function LoadingState({ onClose }: LoadingStateProps) {
  return (
    <>
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Done</NavigationBarButton>
        }
      >
        Equipment
      </NavigationBar>
      <div
        className={cx(
          'tw-flex-1',
          'tw-self-center',
          'tw-flex',
          'tw-items-center',
        )}
      >
        <Loader />
      </div>
    </>
  );
}
