import { Icon } from '@sb/design-system';

interface SpeedBadgeProps {
  hidden?: boolean;
  speedRestrictionPercentage: number;
  isSlowSpeed: boolean;
}

export function SpeedBadge({
  hidden,
  speedRestrictionPercentage,
  isSlowSpeed,
}: SpeedBadgeProps) {
  if (hidden) {
    return null;
  }

  const isRestricted = speedRestrictionPercentage < 1;

  if (!isSlowSpeed && !isRestricted) {
    return null;
  }

  return (
    <>
      <span className="tw-text-label-tertiary tw-ml-2">
        {isSlowSpeed && 'Slow '}
        {isRestricted && `${Math.round(speedRestrictionPercentage * 100)}%`}
      </span>
      <Icon
        className="tw-text-label-tertiary"
        kind={isSlowSpeed ? 'speedometerSlow' : 'speedometerFast'}
      />
    </>
  );
}
