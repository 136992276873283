import cx from 'classnames';

import {
  NavigationListIcon,
  NavigationListItem,
  NavigationListLabel,
} from '@sb/design-system';
import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import type { Equipment, Integrations } from '@sb/remote-control/types';

interface EquipmentManagerItemProps {
  equipment: Equipment.ConvertedResponse;
  name?: string;
  summaryStatus: Integrations.SummaryStatus;
  onClick: () => void;
}

export function EquipmentManagerItem({
  equipment,
  name,
  summaryStatus,
  onClick,
}: EquipmentManagerItemProps) {
  const equipmentFrontendImplementation = getEquipmentByKind(
    equipment.config.kind,
  );

  const integrationType = equipmentFrontendImplementation.getIntegrationType();

  const getSubtitleBelow = () => {
    if (summaryStatus === 'disconnected') {
      return (
        <span className="tw-text-red tw-whitespace-normal">
          This equipment appears to be disconnected. Please ensure that it’s
          properly mounted and connected to the robot. Please also check that
          the attached device matches the Equipment Configuration.
        </span>
      );
    }

    if (summaryStatus?.kind === 'error') {
      return (
        <span className="tw-text-red tw-whitespace-normal">
          This equipment has an error: {summaryStatus.message}
        </span>
      );
    }

    return undefined;
  };

  return (
    <NavigationListItem onClick={onClick}>
      <NavigationListIcon
        kind={equipmentFrontendImplementation.iconKind}
        className={cx(
          integrationType === 'EndEffector' && 'tw-bg-blue',
          integrationType === 'DynamicBase' && 'tw-bg-metal-70',
          integrationType === 'NetworkRequest' && 'tw-bg-purple-80',
          integrationType === 'Camera' && 'tw-bg-green-60',
          integrationType === 'Other' && 'tw-bg-brown-70',
          !equipment.isEnabled && 'tw-opacity-40',
        )}
      />

      <NavigationListLabel
        subtitleAbove={equipmentFrontendImplementation.getManufacturerName()}
        subtitleBelow={getSubtitleBelow()}
      >
        {name ?? equipmentFrontendImplementation.getShortName()}
      </NavigationListLabel>

      {!equipment.isEnabled && (
        <span className="tw-text-label-tertiary tw-text-13">Disabled</span>
      )}

      {(summaryStatus === 'busy' || summaryStatus === 'idle') && (
        <div
          className={cx('tw-w-12', 'tw-h-12', 'tw-rounded-full', 'tw-bg-green')}
          title="Connected"
        />
      )}

      {summaryStatus === 'disconnected' && (
        <div
          className={cx('tw-w-12', 'tw-h-12', 'tw-rounded-full', 'tw-bg-red')}
          title="Disconnected"
        />
      )}

      {summaryStatus?.kind === 'error' && (
        <div
          className={cx('tw-w-12', 'tw-h-12', 'tw-rounded-full', 'tw-bg-red')}
          title={summaryStatus.message}
        />
      )}
    </NavigationListItem>
  );
}
