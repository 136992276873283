import cx from 'classnames';
import isValidHostname from 'is-valid-hostname';

import { TextInput } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';

import { ConfigureTCPTransform } from '../../frontend/ConfigureTCPTransform';
import { ORVGP20_DEFAULT_TCP_TRANSFORM } from '../constants';

export function OnRobotVGP20EquipmentManager({
  item,
  onUpdate,
  setIsFieldValid,
  isFormDisabled,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'OnRobotVGP20') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const { connectionOptions } = item;
  const isHostValid = isValidHostname(connectionOptions.host);

  return (
    <>
      <div className="tw-flex tw-flex-col">
        <h5 className="tw-heading-40 tw-pl-16">Host IP</h5>

        <TextInput
          className={cx(!isHostValid && 'tw-outline-error')}
          size={44}
          value={connectionOptions.host}
          onChange={(host) => {
            setIsFieldValid('host')(isValidHostname(host));

            onUpdate({
              ...item,
              connectionOptions: {
                ...item.connectionOptions,
                host,
              },
            });
          }}
          disabled={isFormDisabled}
        />

        {!isHostValid && (
          <p className={cx('tw-text-red', 'tw-text-13', 'tw-mt-8')}>
            Hostname not valid
          </p>
        )}
      </div>

      <ConfigureTCPTransform
        value={item.tcpTransform}
        onChange={(tcpTransform) => onUpdate({ ...item, tcpTransform })}
        isDisabled={isFormDisabled}
        defaultValue={ORVGP20_DEFAULT_TCP_TRANSFORM}
      />
    </>
  );
}
