import { useState } from 'react';

import {
  Icon,
  NavigationBar,
  NavigationBarBackButton,
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupSwitchItem,
} from '@sb/design-system';
import { ZERO_POSE } from '@sb/geometry';
import {
  CameraStream,
  ChessboardOverlay,
  useVisionChessboardCorners,
} from '@sbrc/components/camera';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import type {
  WristCameraAccuracyCalibrationEntry,
  WristCameraConfiguration,
} from '../types';

import { buildAccuracyCalibrationList } from './buildAccuracyCalibrationList';
import { CaptureCalibrationButton } from './CaptureCalibrationButton';
import { Timestamp } from './Timestamp';

const RECOMMENDED_NUMBER_OF_ENTRIES = 12;

interface AccuracyCalibrationProps {
  initialConfiguration: WristCameraConfiguration;
  onBack: () => void;
  onUpdate: (calibration: WristCameraConfiguration) => void;
}

export function AccuracyCalibration({
  initialConfiguration,
  onBack,
  onUpdate,
}: AccuracyCalibrationProps) {
  const [configuration, setConfiguration] = useState(initialConfiguration);
  const [isCapturing, setIsCapturing] = useState(false);
  const routineRunnerHandle = useRoutineRunnerHandle({});

  const updateAccuracyCalibration = (
    accuracyCalibration: WristCameraAccuracyCalibrationEntry[],
  ) => {
    const newConfiguration = { ...configuration, accuracyCalibration };
    setConfiguration(newConfiguration);
    onUpdate(newConfiguration);
  };

  const updateIsAccuracyCalibrationEnabled = (
    isAccuracyCalibrationEnabled: boolean,
  ) => {
    const newConfiguration = { ...configuration, isAccuracyCalibrationEnabled };
    setConfiguration(newConfiguration);
    onUpdate(newConfiguration);
  };

  const { isDetected, chessboardCorners, handleCapture } =
    useVisionChessboardCorners({
      isOpen: !isCapturing,
      onCapture: (cameraChessboardTransform) => {
        const jointAngles =
          routineRunnerHandle.getState()?.kinematicState.jointAngles;

        if (!jointAngles) {
          throw new Error('Joint angles are not available');
        }

        const newAccuracyCalibrationEntry = {
          jointAngles,
          cameraChessboardTransform,
          timestamp: new Date().toISOString(),
          offset: ZERO_POSE,
        };

        const newAccuracyCalibration = buildAccuracyCalibrationList([
          ...configuration.accuracyCalibration,
          newAccuracyCalibrationEntry,
        ]);

        updateAccuracyCalibration(newAccuracyCalibration);
      },
    });

  return (
    <>
      <NavigationBar contentLeft={<NavigationBarBackButton onClick={onBack} />}>
        Accuracy calibration
      </NavigationBar>

      <div className="tw-flex-1 tw-overflow-auto tw-px-24 tw-pb-24 tw-flex tw-flex-col tw-gap-32">
        <div className="tw-max-w-320 tw-min-h-[128px] tw-self-center tw-rounded-10 tw-overflow-hidden">
          <CameraStream>
            <ChessboardOverlay points={chessboardCorners} />
          </CameraStream>
        </div>

        <SettingsGroup>
          <SettingsGroupSwitchItem
            label="Enable accuracy calibration"
            checked={configuration.isAccuracyCalibrationEnabled}
            onChange={(e) =>
              updateIsAccuracyCalibrationEnabled(e.target.checked)
            }
          />

          <CaptureCalibrationButton
            isFound={isDetected}
            isCapturing={isCapturing}
            setIsCapturing={setIsCapturing}
            onCapture={handleCapture}
          />
        </SettingsGroup>

        <section className="tw-flex tw-flex-col">
          <h5 className="tw-heading-40 tw-pl-16">
            <span>Calibration entries</span>
            <span className="tw-font-regular tw-text-15 tw-text-label-tertiary">
              ({configuration.accuracyCalibration.length})
            </span>
          </h5>

          <SettingsGroup>
            {configuration.accuracyCalibration.map(
              (calibrationEntry, index) => {
                const name = `Calibration ${index + 1}`;

                return (
                  <SettingsGroupItem key={name}>
                    <span>{name}</span>
                    <Timestamp timestamp={calibrationEntry.timestamp} />
                    <Icon
                      className="tw-text-red tw-icon-22"
                      kind="trash"
                      onClick={() => {
                        const newAccuracyCalibration = [
                          ...configuration.accuracyCalibration,
                        ];

                        newAccuracyCalibration.splice(index, 1);
                        updateAccuracyCalibration(newAccuracyCalibration);
                      }}
                    />
                  </SettingsGroupItem>
                );
              },
            )}
          </SettingsGroup>

          {configuration.accuracyCalibration.length <
            RECOMMENDED_NUMBER_OF_ENTRIES && (
            <p className="tw-pl-16 tw-mt-8 tw-text-13 tw-text-label-tertiary">
              It is recommended to capture {RECOMMENDED_NUMBER_OF_ENTRIES}{' '}
              calibration entries from a variety of positions
            </p>
          )}
        </section>
      </div>
    </>
  );
}
