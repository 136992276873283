import { SettingsGroupSelectItem } from '@sb/design-system';

import type { OR3FG15CommandKind } from '../../constants';

interface CommandKindProps {
  value: OR3FG15CommandKind;
  onChange: (value: OR3FG15CommandKind) => void;
  isGripAllowed?: boolean;
}

export function CommandKind({
  value,
  onChange,
  isGripAllowed,
}: CommandKindProps) {
  return (
    <SettingsGroupSelectItem
      label="Command"
      value={value}
      onChange={(event) => {
        switch (event.target.value) {
          case 'flexGrip':
            onChange('flexGrip');
            break;
          case 'grip':
            onChange('grip');
            break;
          default:
            onChange('move');
        }
      }}
    >
      <option value="move">Move</option>
      {isGripAllowed && <option value="grip">Grip</option>}
      <option value="flexGrip">Flexible grip</option>
    </SettingsGroupSelectItem>
  );
}
