import type { Notification } from '@sb/remote-control/types';
import { FailureKind } from '@sb/routine-runner';

export const notificationActionMessages: Array<{
  predicate: (notification: Notification.ConvertedResponse) => boolean;
  message: string;
}> = [
  {
    predicate: (notification) =>
      notification.kind === 'robotFailure' &&
      notification.failureKind === FailureKind.GripperFailure &&
      notification.stepID !== undefined,
    message: [
      'The Actuate Gripper step may be set to close or open to a diameter that can’t be achieved.',
      'When closing on an object, the gripper should be set to close to 1-2mm smaller than',
      'the object that you are trying to close on (but not much smaller than that).',
      'You can get a sense of the diameter it’s currently able to achieve in the error message above,',
      'and could try setting that diameter or 1-2mm smaller in the ActuateGripper step that’s failing.',
    ].join(' '),
  },

  {
    predicate: (notification) =>
      notification.kind === 'robotFailure' &&
      notification.failureKind === FailureKind.GripperFailure,
    message: [
      'The gripper either may be set to close or open to a diameter that can’t be achieved,',
      'is experiencing connectivity issues, or is detached.',
      'If the correct gripper is attached and you are trying to close on an object,',
      'the gripper should be set to close to 1-2mm smaller than the object that',
      'you are trying to close on (but not much smaller than that)',
    ].join(' '),
  },

  {
    predicate: (notification) =>
      notification.kind === 'robotFailure' &&
      notification.failureKind === FailureKind.HaasFailure &&
      /already running/i.test(notification.failureReason),
    message: [
      'You can restart robot main on the CNC mill to alleviate this error,',
      'but if it’s repeatedly happening with robot main already running then let us know.',
    ].join(' '),
  },

  {
    predicate: (notification) =>
      notification.kind === 'robotFailure' &&
      notification.failureKind === FailureKind.InternalFailure,
    message: [
      'An internal failure occurred.',
      'You must restart the robot to recover from this failure.',
      'If this failure persists contact support.',
    ].join(' '),
  },

  {
    predicate: (notification) => notification.kind === 'robotFailure',
    message: [
      'The robot requires recovery.',
      'Navigate to the Routine Editor and press the Play button to access the Recovery panel.',
      'Alternatively, you can navigate to the Move View and switch to',
      '“Controlling Live Robot” to access Recovery controls.',
    ].join(' '),
  },

  {
    predicate: () => true,
    message: 'Contact Standard Bots for further assistance.',
  },
];
