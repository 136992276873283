import { useId } from 'react';

import { Icon, SettingsGroupItem, Switch } from '@sb/design-system';
import type { SoftwareBuildData } from '@sbrc/components/software-update/useSoftwareBuildData';

interface FirmwareUpdateSelectRowProps {
  jointNumber: number;
  jointName: string;
  isSelected: boolean;
  onChange: (isSelected: boolean) => void;
  buildData: SoftwareBuildData;
}

export function FirmwareUpdateSelectRow({
  jointNumber,
  jointName,
  isSelected,
  onChange,
  buildData,
}: FirmwareUpdateSelectRowProps) {
  const id = useId();

  const jointFirmwareInfo = buildData.activeFirmwareInfo?.find(
    (j) => j.jointNumber === jointNumber,
  );

  const buildName = jointFirmwareInfo?.buildInfo?.buildName;

  const status = (() => {
    if (!jointFirmwareInfo?.buildInfo?.buildId) return 'unknown';

    if (jointFirmwareInfo?.buildInfo?.buildId !== buildData.targetBuildID) {
      return 'outdated';
    }

    if (jointFirmwareInfo?.status === 'installed') return 'installed';

    return 'unknown';
  })();

  const icon = {
    installed: (
      <Icon kind="checkmarkCircle" className="tw-mr-4 tw-text-green" />
    ),
    outdated: <Icon kind="arrowDownSquare" className="tw-mr-4 tw-text-blue" />,
    unknown: (
      <Icon kind="questionmarkCircle" className="tw-mr-4 tw-text-yellow-50" />
    ),
  }[status];

  return (
    <SettingsGroupItem className="tw-py-8">
      <div className="tw-flex tw-flex-col tw-justify-center">{icon}</div>
      <div className="tw-flex-1 tw-flex tw-flex-col">
        <label htmlFor={id}>{jointName}</label>
        <span className="tw-text-13 tw-text-label-tertiary">
          {jointNumber} • {buildName ?? 'Unknown build'}
        </span>
      </div>
      <Switch
        checked={isSelected}
        onChange={(e) => onChange(e.target.checked)}
      />
    </SettingsGroupItem>
  );
}
