import { Button, Icon } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';

import { PortDropdown } from './PortDropdown';

interface AddPortButtonProps {
  onAdd: (port: Integrations.IOPort) => void;
  label: string;
}

export function AddPortButton({ onAdd, label }: AddPortButtonProps) {
  return (
    <PortDropdown onSelect={onAdd}>
      {(onOpen) => (
        <Button size={32} onClick={onOpen} className="tw-rounded-6">
          <Icon kind="plus" />
          <span>{label}</span>
        </Button>
      )}
    </PortDropdown>
  );
}
