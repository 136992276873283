import { useMemo } from 'react';

import { Icon, Tooltip } from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import type { CameraIntrinsics } from '@sb/integrations/camera/types';
import { convertEulerPose } from '@sbrc/utils';

interface IntrinsicsInfoTooltipProps {
  intrinsics: CameraIntrinsics;
  wristToCameraTransform: CartesianPose | null;
}

export function IntrinsicsInfoTooltip({
  intrinsics,
  wristToCameraTransform,
}: IntrinsicsInfoTooltipProps) {
  const props = useMemo(() => {
    const props1: Record<string, number> = { ...intrinsics };

    if (wristToCameraTransform) {
      const eulerPose = convertEulerPose.fromCartesian(wristToCameraTransform);

      props1.offsetX = eulerPose.X;
      props1.offsetY = eulerPose.Y;
      props1.offsetZ = eulerPose.Z;
      props1.roll = eulerPose.Rx;
      props1.pitch = eulerPose.Ry;
      props1.yaw = eulerPose.Rz;
    }

    return props1;
  }, [intrinsics, wristToCameraTransform]);

  return (
    <Tooltip
      className="tw-inline-block tw-align-top tw-ml-4"
      content={
        <>
          {Object.entries(props).map(([key, value]) => (
            <div key={key} className="tw-text-left tw-text-13">
              {key}: {value}
            </div>
          ))}
        </>
      }
    >
      <Icon kind="infoCircle" className="tw-icon-18" />
    </Tooltip>
  );
}
