import { Modal, NavigationBar, NavigationBarButton } from '@sb/design-system';

import { DataBackupModalBody } from './DataBackupModalBody';

interface DataBackupModalProps {
  isOpen: boolean;
  onClose: () => void;
  robotID: string;
}

export const DataBackupModal = ({
  isOpen,
  robotID,
  onClose,
}: DataBackupModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Cancel</NavigationBarButton>
        }
      >
        Backups
      </NavigationBar>
      <DataBackupModalBody robotID={robotID} />
    </Modal>
  );
};
