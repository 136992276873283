import { useTheme } from 'next-themes';
import { useEffect } from 'react';

import { useFeatureFlag } from '@sbrc/hooks';

// Allow system switching light/dark mode by feature flag
// Remove this function after removing useFeatureFlag('darkMode')
export function DarkModeView() {
  const isDarkModeEnabled = useFeatureFlag('darkMode');
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme(isDarkModeEnabled ? 'dark' : 'light');
  }, [isDarkModeEnabled, setTheme]);

  return null;
}
