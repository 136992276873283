import cx from 'classnames';

import { Icon } from '@sb/design-system';

export type DriveStatusProps = {
  className?: string;
  status: 'creating' | 'restoring' | 'failed' | 'success' | undefined; // 'none' | 'success' | 'failure'
};

export const DriveStatusIcon = ({ className, status }: DriveStatusProps) => {
  const badgeStyles =
    'tw-absolute tw-flex tw-items-center tw-justify-center tw-left-[60%] tw-top-[45%] tw-w-20 tw-h-20 tw-rounded-full tw-text-light';

  return (
    <div className={cx('tw-relative', className)}>
      <Icon kind="driveStorage" className="tw-icon-48" />
      {status === 'success' && (
        <div className={cx(badgeStyles, 'tw-bg-green')}>
          <Icon kind="checkmark" className="tw-icon-18" />
        </div>
      )}
      {status === 'failed' && (
        <div className={cx(badgeStyles, 'tw-bg-red')}>
          <Icon kind="xmark" className="tw-icon-18" />
        </div>
      )}
    </div>
  );
};
