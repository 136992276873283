import cx from 'classnames';
import { useState } from 'react';

import { Icon, SettingsGroupItem } from '@sb/design-system';

interface SoftwareInstallErrorMessageProps {
  lastErrorMessage?: string;
  isInstallingSoftware: boolean;
}

export function SoftwareInstallErrorMessage({
  lastErrorMessage,
  isInstallingSoftware,
}: SoftwareInstallErrorMessageProps) {
  const [showFullErrorMessage, setShowFullErrorMessage] = useState(false);

  return (
    <>
      {isInstallingSoftware && lastErrorMessage && (
        <p
          className={cx('tw-px-16', 'tw-text-13', 'tw-text-red', 'tw-icon-20')}
        >
          <span>A problem occurred during the software install process.</span>
          {!showFullErrorMessage && (
            <Icon
              kind="infoCircle"
              className={cx('tw-ml-4', 'tw-inline-block')}
              onClick={() => setShowFullErrorMessage(true)}
            />
          )}
        </p>
      )}

      {lastErrorMessage && showFullErrorMessage && (
        <SettingsGroupItem
          isSeparated
          className={cx(
            'tw-text-13',
            'tw-text-label-tertiary',
            'tw-whitespace-normal',
            'tw-py-8',
          )}
        >
          {lastErrorMessage}
        </SettingsGroupItem>
      )}
    </>
  );
}
