import type { Integrations } from '@sb/remote-control/types';

export function InvalidConfigurationEquipmentManager({
  item,
}: Integrations.EquipmentManagerEditFormProps) {
  return (
    <p className="tw-text-15 tw-text-label-secondary">
      The configuration saved for “{item.name}” is not valid. Remove this
      configuration and recreate it.
      <pre>{JSON.stringify(item, null, 2)}</pre>
    </p>
  );
}
