import { clamp } from 'lodash';
import { useMemo, useState } from 'react';

import { PAYLOAD_MASS_KG_DEFAULT } from '@sb/routine-runner';
import { isApproximatelyEqual } from '@sb/utilities';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotStateKind,
} from '@sbrc/hooks';

import {
  type OR3FG15CommandKind,
  type OR3FG15GripKind,
  OR_3FG15_DIAMETER_METERS_SLIDER_STEP,
  OR_3FG15_FORCE_NEWTONS_SLIDER_STEP,
  OR_3FG15_TARGET_FORCE_DEFAULT,
} from '../../constants';
import type { OnRobot3FG15Command, OnRobot3FG15State } from '../../types';
import { calculateOR3FG15DiameterRangeFromConfig } from '../../utils';

import { getOR3FG15ForceRange } from './constants';

type GripperRange = {
  min: number;
  max: number;
};

type GripperControlStateOutput = {
  canApplyGripperChanges: boolean;
  command: OnRobot3FG15Command;
  resetAll: () => void;

  commandKind: OR3FG15CommandKind;
  setCommandKind: (commandKind: OR3FG15CommandKind) => void;

  setGripKind: (gripKind: OR3FG15GripKind) => void;

  diameterRange: GripperRange;
  currentDiameter: number;
  setTargetDiameter: (targetDiameter: number) => void;
  isDiameterEqual: boolean;

  forceRange: GripperRange;
  currentForce: number;
  setTargetForce: (targetForce: number) => void;
  isForceEqual: boolean;

  currentPayload: number;
  setTargetPayload: (targetPayload: number) => void;
  targetPayload: number;
  isPayloadEqual: boolean;

  status: {
    isBusy: boolean;
    isCalibrationOk: boolean;
    isConnected: boolean;
    isForceGripDetected: boolean;
    isGripDetected: boolean;
  };
};

interface UseGripperControlStateArguments {
  isVizbot: boolean;
  routineRunnerGripperState: OnRobot3FG15State;
  routineRunnerPayload: number | null;
}

export function useGripperControlState({
  isVizbot,
  routineRunnerPayload,
  routineRunnerGripperState,
}: UseGripperControlStateArguments): GripperControlStateOutput {
  const [commandKind, setCommandKind] = useState<OR3FG15CommandKind>('move');

  const currentGripKind = routineRunnerGripperState.gripKind;

  const [gripKind = currentGripKind, setGripKind] = useState<
    OR3FG15GripKind | undefined
  >();

  /* diameter */

  const diameterRange: GripperRange = useMemo(() => {
    return calculateOR3FG15DiameterRangeFromConfig(
      routineRunnerGripperState.fingerConfiguration,
      gripKind,
    );
  }, [gripKind, routineRunnerGripperState.fingerConfiguration]);

  const currentDiameter =
    currentGripKind === gripKind
      ? routineRunnerGripperState.diameterMeters
      : NaN; // diameter in gripper state is not relevant when switching grip kind

  const [
    targetDiameter = routineRunnerGripperState.diameterMeters,
    setTargetDiameter,
  ] = useState<number | undefined>();

  const isDiameterEqual = isApproximatelyEqual(
    currentDiameter,
    targetDiameter,
    OR_3FG15_DIAMETER_METERS_SLIDER_STEP,
  );

  /* force */

  const forceRange = getOR3FG15ForceRange(commandKind);

  const currentForce =
    routineRunnerGripperState.targetForceNewtons ??
    OR_3FG15_TARGET_FORCE_DEFAULT;

  const [targetForce = currentForce, setTargetForce] = useState<
    number | undefined
  >();

  const isForceEqual = isApproximatelyEqual(
    currentForce,
    targetForce,
    OR_3FG15_FORCE_NEWTONS_SLIDER_STEP,
  );

  /* payload */

  const currentPayload = routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT;

  const [targetPayload = currentPayload, setTargetPayload] = useState<
    number | undefined
  >();

  const isPayloadEqual = currentPayload === targetPayload;

  /* other */

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    isVizbot,
  });

  const isRoutineRunning = useRobotStateKind({ isVizbot }) === 'RoutineRunning';

  const canApplyGripperChanges =
    !isRoutineRunning &&
    !isAnotherSessionMovingRobot &&
    (!isDiameterEqual ||
      !isForceEqual ||
      currentGripKind !== gripKind ||
      routineRunnerPayload !== targetPayload);

  const command = useMemo<OnRobot3FG15Command>(
    () => ({
      kind: 'OnRobot3FG15Command',
      gripKind,
      targetDiameter: clamp(
        targetDiameter,
        diameterRange.min,
        diameterRange.max,
      ),
      targetForce: clamp(targetForce, forceRange.min, forceRange.max),
      isFlexGrip: commandKind === 'flexGrip',
      waitForGripToContinue: commandKind === 'grip',
    }),
    [
      commandKind,
      gripKind,
      targetDiameter,
      diameterRange.min,
      diameterRange.max,
      targetForce,
      forceRange.min,
      forceRange.max,
    ],
  );

  const resetAll = () => {
    setGripKind(undefined);
    setTargetDiameter(undefined);
    setTargetForce(undefined);
    setTargetPayload(undefined);
  };

  return {
    canApplyGripperChanges,
    command,
    resetAll,

    commandKind,
    setCommandKind,

    setGripKind,

    diameterRange,
    currentDiameter,
    setTargetDiameter,
    isDiameterEqual,

    forceRange,
    currentForce,
    setTargetForce,
    isForceEqual,

    currentPayload,
    setTargetPayload,
    targetPayload,
    isPayloadEqual,

    status: {
      isBusy: routineRunnerGripperState.isBusy,
      isCalibrationOk: routineRunnerGripperState.isCalibrationOk,
      isConnected: routineRunnerGripperState.isConnected,
      isForceGripDetected: routineRunnerGripperState.isForceGripDetected,
      isGripDetected: routineRunnerGripperState.isGripDetected,
    },
  };
}
