import { useMemo } from 'react';

import { SettingsGroupFullWidthSelectItem } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { Loader } from '@sb/ui/components';
import { useEquipment } from '@sbrc/hooks';

import type { DualChangerChildren } from '..';

interface GripperSelectProps {
  onGripperChange: (action: DualChangerChildren) => void;
  selectedGripper: DualChangerChildren;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
  className?: string;
}

export function GripperSelect({
  onGripperChange,
  selectedGripper,
  getEquipmentByKind,
  className,
}: GripperSelectProps) {
  const equipmentList = useEquipment();

  const dualQuickChanger = equipmentList?.find(
    (equip) => equip.config.kind === 'OnRobotDualQuickChanger',
  );

  const dualImplementations = useMemo(() => {
    if (dualQuickChanger?.config.kind === 'OnRobotDualQuickChanger') {
      const primary = getEquipmentByKind(
        dualQuickChanger.config.grippers.primary.kind,
      );

      const secondary = getEquipmentByKind(
        dualQuickChanger.config.grippers.secondary.kind,
      );

      return {
        primaryName: `Primary: ${primary.getDisplayName()}`,
        secondaryName: `Secondary: ${secondary.getDisplayName()}`,
      };
    }

    return undefined;
  }, [dualQuickChanger, getEquipmentByKind]);

  if (!dualImplementations) return <Loader />;

  return (
    <SettingsGroupFullWidthSelectItem
      isSeparated
      value={selectedGripper}
      onChange={(event) => {
        switch (event.target.value) {
          case 'secondary':
            onGripperChange('secondary');
            break;
          default:
            onGripperChange('primary');
        }
      }}
      className={className}
    >
      <option value="primary">{dualImplementations.primaryName}</option>
      <option value="secondary">{dualImplementations.secondaryName}</option>
    </SettingsGroupFullWidthSelectItem>
  );
}
