import cx from 'classnames';

import styles from './WidgetView.module.css';

interface WidgetViewProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  /** will be full iPad height, or at least 640px height on larger viewports */
  isFullHeight?: boolean;
}

export default function WidgetView({
  children,
  className,
  isFullHeight,
  ...rest
}: WidgetViewProps) {
  return (
    <div
      {...rest}
      className={cx(
        className,
        'tw-surface-secondary',
        'tw-shadow-80',
        'tw-rounded-18',
        styles.content,
        isFullHeight && 'tw-min-h-[min(var(--widget-max-height),640px)]',
      )}
    >
      {children}
    </div>
  );
}
