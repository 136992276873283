import cx from 'classnames';
import { useState } from 'react';

import { Menu, MenuItem, Popper } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { IOBadge } from '@sbrc/components/io-manager';

import { usePortDropdownData } from './usePortDropdownData';

interface PortDropdownProps {
  selectedPort?: Integrations.IOPort;
  onSelect: (port: Integrations.IOPort) => void;
  onUnassign?: () => void;
  children: (onToggle: () => void) => React.ReactNode;
}

export function PortDropdown({
  selectedPort,
  onSelect,
  onUnassign,
  children,
}: PortDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const portDropdownData = usePortDropdownData({ isOpen, selectedPort });

  return (
    <Popper
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="right"
      className="tw-inline-block"
      content={
        <Menu className="tw-h-[80dvh]">
          {onUnassign && (
            <MenuItem className="tw-text-red" onClick={onUnassign}>
              Unassign
            </MenuItem>
          )}

          {portDropdownData.map(({ port, isDisabled, label }) => (
            <MenuItem
              key={port.port}
              aria-label={`${port.kind} port ${port.port}`}
              onClick={() => onSelect(port)}
              disabled={isDisabled}
            >
              <div className={cx('tw-flex', 'tw-items-center', 'tw-gap-8')}>
                <IOBadge>
                  {port.kind === 'Input' ? 'IN' : 'OUT'} {port.port}
                </IOBadge>
                {label}
              </div>
            </MenuItem>
          ))}
        </Menu>
      }
    >
      {children(() => setIsOpen(!isOpen))}
    </Popper>
  );
}
