import { SettingsGroupSelectItem } from '@sb/design-system';

import type { DualValueModeProps } from './types';

export default function GripKind({ gripperControlState }: DualValueModeProps) {
  const { setGripKind, command, isDisabled } = gripperControlState;

  return (
    <SettingsGroupSelectItem
      isSeparated
      label="Grip kind"
      value={command.gripKind}
      onChange={(event) => {
        switch (event.target.value) {
          case 'inward':
            setGripKind('inward');
            break;
          default:
            setGripKind('outward');
        }
      }}
      disabled={isDisabled}
    >
      <option value="inward">Inward</option>
      <option value="outward">Outward</option>
    </SettingsGroupSelectItem>
  );
}
