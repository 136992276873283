import { Icon } from '@sb/design-system';
import type { JointStatusKind } from '@sbrc/hooks';

import {
  getFirmwareInstallIcon,
  getFirmwareInstallColor,
  getFirmwareInstallText,
} from './util';

interface FirmwareStatusIconProps {
  status: JointStatusKind;
}

export function FirmwareStatusIcon({ status }: FirmwareStatusIconProps) {
  return (
    <span className="tw-flex tw-flex-row tw-items-center tw-gap-8">
      <Icon
        kind={getFirmwareInstallIcon(status)}
        className={getFirmwareInstallColor(status)}
      />
      <span>{getFirmwareInstallText(status)}</span>
    </span>
  );
}
