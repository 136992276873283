import { useCallback, useState } from 'react';

import { OR_3FG15_COMMAND_DEFAULT } from '@sb/integrations/OnRobot3FG15/widget/gripper-control-state/constants';

import type { DualChangerChildren } from '../DualChangerChildren';
import type {
  OnRobotDualQuickChangerCompatibleCommand,
  OnRobotDualQuickChangerCommand,
} from '../types';

const getDefaults = (
  defaultCommand?: Partial<OnRobotDualQuickChangerCommand>,
): OnRobotDualQuickChangerCommand => {
  return {
    kind: defaultCommand?.kind ?? 'OnRobotDualQuickChangerCommand',
    active: defaultCommand?.active ?? 'primary',
    command: defaultCommand?.command ?? OR_3FG15_COMMAND_DEFAULT,
  };
};

type GripperControlStateOutput = {
  changeActiveGripper: (action: DualChangerChildren) => void;
  changeActiveGripperCommand: (
    action: OnRobotDualQuickChangerCompatibleCommand,
  ) => void;
  command: OnRobotDualQuickChangerCommand;
};

export function useGripperControlState(
  defaultCommand?: Partial<OnRobotDualQuickChangerCommand>,
): GripperControlStateOutput {
  const [command, setCommand] = useState<OnRobotDualQuickChangerCommand>(
    getDefaults(defaultCommand),
  );

  const changeActiveGripper = (action: DualChangerChildren) => {
    setCommand((previousState) => {
      return {
        ...previousState,
        active: action ?? 'primary',
      };
    });
  };

  const changeActiveGripperCommand = useCallback(
    (action: OnRobotDualQuickChangerCompatibleCommand) => {
      setCommand((previousState) => {
        return {
          ...previousState,
          command: action ?? previousState.command,
        };
      });
    },
    [],
  );

  return {
    changeActiveGripper,
    changeActiveGripperCommand,
    command,
  };
}
