import { SettingsGroup } from '@sb/design-system';
import { joints } from '@sb/utilities/src/joints';
import { FirmwareUpdateSelectRow } from '@sbrc/components/software-update/FirmwareUpdateSelectRow';
import type { SoftwareBuildData } from '@sbrc/components/software-update/useSoftwareBuildData';

function calculateSelectedJoints(
  selectedJoints: number[],
  changedJoint: number,
  isSelected: boolean,
): number[] {
  const updatedSelectedJoints = [...selectedJoints];

  if (isSelected && !selectedJoints.includes(changedJoint)) {
    updatedSelectedJoints.push(changedJoint);
  } else if (!isSelected && selectedJoints.includes(changedJoint)) {
    const index = updatedSelectedJoints.indexOf(changedJoint);
    updatedSelectedJoints.splice(index, 1);
  }

  return updatedSelectedJoints.sort((a, b) => a - b);
}

interface FirmwareUpdateSelectPanelProps {
  buildData: SoftwareBuildData;
}

export function FirmwareUpdateSelectPanel({
  buildData,
}: FirmwareUpdateSelectPanelProps) {
  return (
    <SettingsGroup className="tw-mb-24">
      {joints.map(({ jointNumber, jointName }) => (
        <FirmwareUpdateSelectRow
          key={jointNumber}
          jointNumber={jointNumber}
          jointName={jointName}
          buildData={buildData}
          isSelected={buildData.selectedJointsForUpdate.includes(jointNumber)}
          onChange={(isSelected) => {
            const newSelectedJointsForUpdate = calculateSelectedJoints(
              buildData.selectedJointsForUpdate,
              jointNumber,
              isSelected,
            );

            buildData.setSelectedJointsForUpdate(newSelectedJointsForUpdate);
          }}
        />
      ))}
    </SettingsGroup>
  );
}
