import { Typography } from '@sb/ui/components';

import styles from './LimitsSectionHeader.module.css';

interface LimitsSectionHeaderProps {
  title: string;
}

export function LimitsSectionHeader({ title }: LimitsSectionHeaderProps) {
  return (
    <>
      <Typography className={styles.cell} isBold variant="medium">
        {title}
      </Typography>
      <Typography className={styles.cell}>Full Speed</Typography>
      <Typography className={styles.cell}>Slow Speed</Typography>
      <div className={styles.cell}>&nbsp;</div>
    </>
  );
}
