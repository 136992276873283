import { SettingsGroupSelectItem } from '@sb/design-system';

import type { OR3FG15GripKind } from '../../constants';

interface GripKindProps {
  value: OR3FG15GripKind;
  onChange: (value: OR3FG15GripKind) => void;
  isDisabled?: boolean;
}

export function GripKind({ value, onChange, isDisabled }: GripKindProps) {
  return (
    <SettingsGroupSelectItem
      label="Grip kind"
      value={value}
      onChange={(event) => {
        switch (event.target.value) {
          case 'inward':
            onChange('inward');
            break;
          default:
            onChange('outward');
        }
      }}
      disabled={isDisabled}
    >
      <option value="inward">Inward</option>
      <option value="outward">Outward</option>
    </SettingsGroupSelectItem>
  );
}
