import cx from 'classnames';

interface MenuCardItemProps {
  children: React.ReactNode;
}

export function MenuCardItem({ children }: MenuCardItemProps) {
  /* eslint-disable jsx-a11y/no-static-element-interactions */

  return (
    <li
      className={cx(
        'tw-px-16',
        'tw-py-12',
        'tw-flex',
        'tw-flex-col',
        'tw-gap-12',
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </li>
  );
}
