import cx from 'classnames';

import { SettingsGroup } from '@sb/design-system';

import ApplyChangesButton from './ApplyChangesButton';
import { CommandKind } from './CommandKind';
import { GripKind } from './GripKind';
import GripperDiameter from './GripperDiameter';
import GripperForce from './GripperForce';
import GripperPayload from './GripperPayload';
import { GripperStatus } from './GripperStatus';
import type { DualValueModeProps } from './types';

export function GripperControlDualValueMode(props: DualValueModeProps) {
  const { gripperControlState } = props;

  return (
    <>
      <div
        className={cx(
          'tw-flex',
          'tw-flex-col',
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-gap-16',
          'tw-px-16',
        )}
      >
        <SettingsGroup>
          <CommandKind
            value={gripperControlState.commandKind}
            onChange={gripperControlState.setCommandKind}
            isGripAllowed
          />
          <GripKind
            value={gripperControlState.command.gripKind}
            onChange={gripperControlState.setGripKind}
          />
        </SettingsGroup>

        <GripperPayload {...props} />
        <GripperForce {...props} />
        <GripperDiameter {...props} />
        <div />
      </div>

      <hr className="tw-border-divider-primary" />
      <GripperStatus status={gripperControlState.status} />
      <ApplyChangesButton {...props} />
    </>
  );
}
