import { uploadFileToStorage } from '@sbrc/services';

import { getCameraStreamFrame } from './getCameraStreamFrame';

export async function uploadCameraFrame(): Promise<string> {
  const uploadResponse = await uploadFileToStorage({
    file: await getCameraStreamFrame(),
  });

  return uploadResponse.id;
}
