import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import type { Notification } from '@sb/remote-control/types';

import { notificationActionMessages } from './notificationActionMessages';

export const getNotificationMessage = (
  notification: Notification.ConvertedResponse | null,
) => {
  if (!notification) return null;

  if (notification.kind === 'robotFailure')
    return `Robot ${notification.robotID} has failed`;

  return '';
};

export const getNotificationReason = (
  notification: Notification.ConvertedResponse | null,
) => {
  if (!notification) return null;

  if (notification.kind === 'robotFailure') return notification.failureReason;

  return '';
};

export const getNotificationAction = (
  notification: Notification.ConvertedResponse | null,
) => {
  if (notification) {
    for (const { message, predicate } of notificationActionMessages) {
      if (predicate(notification)) {
        return message;
      }
    }
  }

  return null;
};

dayjs.extend(duration);
dayjs.extend(relativeTime);

export function getNotificationAgeHumanized(
  notification: Notification.ConvertedResponse,
): string {
  return dayjs
    .duration(notification.createdAt.valueOf() - Date.now())
    .humanize(true);
}
