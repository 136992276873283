import { useEffect, useState } from 'react';

import type { CartesianPose } from '@sb/geometry';
import * as log from '@sb/log';
import type { ChessboardParameters, Point2D } from '@sb/routine-runner';
import { wait } from '@sb/utilities';
import { useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import { DEFAULT_WRIST_CAMERA } from './wrist-camera/consts';

export const CHESSBOARD_PARAMS: ChessboardParameters = {
  cols: 7,
  rows: 5,
  squareSize: 0.03, // specified in meters
};

interface UseVisionChessboardCornersArgs {
  isOpen: boolean;
  onCapture: (transform: CartesianPose) => void;
}

export function useVisionChessboardCorners({
  isOpen,
  onCapture,
}: UseVisionChessboardCornersArgs) {
  const routineRunnerHandle = useRoutineRunnerHandle({});
  const { setToast } = useToast();

  const [chessboardCorners, setChessboardCorners] = useState<Point2D[] | null>(
    null,
  );

  const [isDetected, setIsDetected] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setChessboardCorners(null);

      return undefined;
    }

    let cancelled = false;

    const getChessboardCorners = async () => {
      while (!cancelled) {
        try {
          const cornersResult = await routineRunnerHandle.runVisionMethod({
            method: 'getChessboardCorners',
            camera: DEFAULT_WRIST_CAMERA,
            cols: CHESSBOARD_PARAMS.cols,
            rows: CHESSBOARD_PARAMS.rows,
          });

          if (cornersResult.method === 'getChessboardCorners') {
            setChessboardCorners(cornersResult.results.corners);
            setIsDetected(cornersResult.results.isDetected);
          }
        } catch (error) {
          log.error(
            'useVisionChessboardCorners.getChessboardCorners.error',
            'Get chessboard corners failed',
            { error },
          );

          setToast({
            kind: 'error',
            message: `Get chessboard corners failed: ${error}`,
          });

          setChessboardCorners(null);
          setIsDetected(false);
        }

        await wait(1000);
      }
    };

    getChessboardCorners();

    return () => {
      cancelled = true;
    };
  }, [routineRunnerHandle, isOpen, setToast]);

  const handleCapture = async () => {
    try {
      const transformResult = await routineRunnerHandle.runVisionMethod({
        method: 'getCameraChessboardTransform',
        camera: DEFAULT_WRIST_CAMERA,
        chessboard: CHESSBOARD_PARAMS,
      });

      if (transformResult.method === 'getCameraChessboardTransform') {
        onCapture(transformResult.results);
      }
    } catch (error) {
      log.error(
        'useVisionChessboardCorners.handleCapture.error',
        'getCameraChessboardTransform failed',
        { error },
      );

      setToast({
        kind: 'error',
        message: 'Failed to capture calibration board.',
      });
    }
  };

  return {
    isDetected,
    chessboardCorners,
    handleCapture,
  };
}
