import { Modal, NavigationBar, NavigationBarButton } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';

import { DeveloperAPIModalBody } from './DeveloperAPIModalBody';

interface DeveloperAPIModalProps {
  isOpen: boolean;
  onClose: () => void;
  robot: Robot.ConvertedResponse;
}

export const DeveloperAPIModal = ({
  isOpen,
  onClose,
  robot,
}: DeveloperAPIModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen onClose={onClose}>
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Done</NavigationBarButton>
        }
      >
        Developer API
      </NavigationBar>
      <div className="tw-p-24">
        <DeveloperAPIModalBody robot={robot} />
      </div>
    </Modal>
  );
};
