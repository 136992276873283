import { Fragment } from 'react';

import { Button, SelectItem, Select, InputField } from '@sb/ui/components';
import { TrashIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';

import { MODBUS_TCP_SERVER_REGISTER_FORMAT_DEFAULT } from '../constants';
import type {
  ModbusTCPRegisterData,
  ModbusTCPServerRegisterFormat,
  ModbusTCPServerRegisterKind,
} from '../types';
import { convertRegisterTypeToDisplayName, isCoilRegister } from '../util';

const HEXADECIMAL_BASE_VALUE = 16;

function convertHexInputToNumber(hex: string): number {
  if (hex.startsWith('0x') || hex === '') {
    return NaN;
  }

  return parseInt(hex, HEXADECIMAL_BASE_VALUE);
}

function isValidHexadecimal(input: string) {
  if (input === '') return false;

  const isValidHex = /^[0-9a-f]+$/i.test(input.toLowerCase());

  return isValidHex;
}

const registerKindOptions: Array<{
  name: string;
  value: ModbusTCPServerRegisterKind;
}> = [
  { name: 'Holding Register', value: 'holdingRegister' },
  { name: 'Input Register', value: 'inputRegister' },
  { name: 'Status Coil', value: 'statusCoil' },
  { name: 'Input Coil', value: 'inputCoil' },
];

interface ModbusTCPServerRegisterFieldListProps {
  registerFieldList: Array<ModbusTCPRegisterData>;
  isFormDisabled: boolean;
  removeRegisterFieldRow: (id: string) => void;
  onChange: (value: ModbusTCPRegisterData) => void;
  setIsFieldValid: (field: string) => (isValid: boolean) => void;
}

export function ModbusTCPServerRegisterFieldList({
  registerFieldList,
  isFormDisabled,
  removeRegisterFieldRow,
  onChange,
  setIsFieldValid,
}: ModbusTCPServerRegisterFieldListProps) {
  const updateRegisterDataFormat = (
    kind: ModbusTCPServerRegisterKind,
    format: ModbusTCPServerRegisterFormat,
  ) => {
    if (isCoilRegister(kind)) {
      return 'bit';
    }

    // set back to default when changing back to non coil from coil type
    if (!isCoilRegister(kind) && format === 'bit') {
      return MODBUS_TCP_SERVER_REGISTER_FORMAT_DEFAULT;
    }

    return format;
  };

  return (
    <>
      {registerFieldList.map((register) => {
        const isValidOffset = !Number.isNaN(register.offset);

        return (
          <Fragment key={register.id}>
            {/* register name */}
            <InputField
              inputContainerClassName={margin.right.small}
              disabled={isFormDisabled}
              data-testid="input-register-name"
              value={register.name}
              onChange={(e) => {
                onChange({
                  ...register,
                  name: e.target.value,
                });
              }}
            />

            {/* register type */}
            <Select
              className={margin.right.small}
              data-testid="select-register-type"
              activeLabel={convertRegisterTypeToDisplayName(register.type)}
              isDisabled={isFormDisabled}
            >
              {registerKindOptions.map((kind) => {
                return (
                  <SelectItem
                    key={kind.value}
                    value={kind.value}
                    onClick={() => {
                      onChange({
                        ...register,
                        type: kind.value,
                        format: updateRegisterDataFormat(
                          kind.value,
                          register.format,
                        ),
                      });
                    }}
                  >
                    {kind.name}
                  </SelectItem>
                );
              })}
            </Select>

            {/* Byte offset */}
            <InputField
              inputContainerClassName={margin.right.small}
              data-testid="input-register-offset"
              disabled={isFormDisabled}
              value={
                Number.isNaN(register.offset)
                  ? ''
                  : register.offset
                      .toString(HEXADECIMAL_BASE_VALUE)
                      .toUpperCase()
              }
              onChange={(e) => {
                const offset = e.target.value;

                setIsFieldValid('offset')(isValidHexadecimal(offset));

                onChange({
                  ...register,
                  offset: convertHexInputToNumber(offset),
                });
              }}
              hasError={!isValidOffset}
            />

            {/* data format */}
            <InputField
              inputContainerClassName={margin.right.small}
              data-testid="select-register-data-format"
              value={register.format}
              disabled
            />

            <Button
              startIcon={<TrashIcon />}
              variant="alertSecondary"
              onClick={() => {
                removeRegisterFieldRow(register.id);
              }}
            />
          </Fragment>
        );
      })}
    </>
  );
}
