import { HoldableButton } from '@sb/design-system';
import { useGuidedMode, useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import type { DualValueModeProps } from './types';

export default function ApplyChangesButton({
  gripperControlState,
  isVizbot,
  dualChangerCommand,
}: DualValueModeProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({
    isVizbot,
    shouldStopBeforeRun: false,
  });

  const { canApplyGripperChanges, isDiameterEqual, resetAll, targetPayload } =
    gripperControlState;

  const onHoldApplyChangesButton = () => {
    runAdHocCommand({
      onRunCommand: async () => {
        const actuateGripper = await routineRunnerHandle.actuateDevice({
          command: dualChangerCommand ?? gripperControlState.command,
          payload: { mass: targetPayload },
          retryTimeoutMS: 10,
        });

        resetAll();

        return actuateGripper;
      },
      onError: (error) => {
        setToast({
          kind: 'error',
          message: `Error actuating gripper: ${error}`,
        });
      },
    });
  };

  const action = isDiameterEqual ? 'Tap' : 'Hold';

  return (
    <HoldableButton
      variant="Filled"
      className="tw-rounded-6 tw-mx-16 tw-mb-16"
      onHold={onHoldApplyChangesButton}
      onRelease={stopGuidedMode}
      disabled={!canApplyGripperChanges}
      data-testid="gripper-widget-apply-changes-button"
    >
      {action} to Apply Changes
    </HoldableButton>
  );
}
