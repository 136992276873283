import type { Robot } from '@sb/remote-control/types';

import ModalOuter from './ModalOuter';
import { StoreProvider } from './store';

interface SafetySettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  robot: Robot.ConvertedResponse;
}

export default function SafetySettingsModal({
  isOpen,
  onClose,
  robot,
}: SafetySettingsModalProps) {
  // unmount when closed, so the store is destroyed
  if (!isOpen) {
    return null;
  }

  return (
    <StoreProvider ioInputs={robot.ioInputs}>
      <ModalOuter robotID={robot.id} onClose={onClose} />
    </StoreProvider>
  );
}
