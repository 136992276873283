import type { Integrations } from '@sb/remote-control/types';

import { customGripperImplementation } from '../CustomGripper/frontend';
import { customIOImplementation } from '../CustomIO/frontend';
import { ewellixLiftTLTImplementation } from '../EwellixLiftTLT/frontend';
import { invalidConfigurationImplementation } from '../InvalidConfiguration/frontend';
import { modbusTCPServerImplementation } from '../ModbusTCPServer/frontend/frontend';
import { noGripperImplementation } from '../NoGripper/frontend';
import { onRobot2FG7Implementation } from '../OnRobot2FG7/frontend';
import { onRobot3FG15Implementation } from '../OnRobot3FG15/frontend';
import { onRobotDualQuickChangerImplementation } from '../OnRobotDualQuickChanger/frontend';
import { onRobotScrewdriverImplementation } from '../OnRobotScrewdriver/frontend';
import { onRobotVGP20Implementation } from '../OnRobotVGP20/frontend';
import { wristCameraImplementation } from '../WristCamera/frontend';

export const getEquipmentByKind: Integrations.GetEquipmentByKind = (
  kind,
): Integrations.IntegrationFrontend<any> => {
  switch (kind) {
    case 'CustomIO':
      return customIOImplementation;
    case 'ModbusTCPServer':
      return modbusTCPServerImplementation;
    case 'EwellixLiftTLT':
      return ewellixLiftTLTImplementation;
    case 'OnRobot2FG7':
      return onRobot2FG7Implementation;
    case 'OnRobot3FG15':
      return onRobot3FG15Implementation;
    case 'OnRobotDualQuickChanger':
      return onRobotDualQuickChangerImplementation;
    case 'OnRobotScrewdriver':
      return onRobotScrewdriverImplementation;
    case 'OnRobotVGP20':
      return onRobotVGP20Implementation;
    case 'CustomGripper':
      return customGripperImplementation;
    case 'NoGripper':
      return noGripperImplementation;
    case 'WristCamera':
      return wristCameraImplementation;
    case 'InvalidConfiguration':
      return invalidConfigurationImplementation;
    default: {
      const exhaustive: never = kind; // ensure type exhaustive
      throw new Error(`Unexpected kind for equipment ${exhaustive}`);
    }
  }
};
