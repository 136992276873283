import cx from 'classnames';

import { Button, Icon } from '@sb/design-system';

import styles from './SuctionChannelCheckbox.module.css';

interface SuctionChannelCheckboxProps {
  name: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  suctionPercentage: number;
}

export function SuctionChannelCheckbox({
  name,
  isChecked,
  onChange,
  suctionPercentage,
}: SuctionChannelCheckboxProps) {
  const suctionPercentage100 = Math.round(suctionPercentage * 100);

  const containerStyle: any = {
    '--suction-channel-checkbox--color-stop-percent': `${suctionPercentage100}%`,
  };

  return (
    <Button
      style={containerStyle}
      className={cx(
        styles.container,
        'tw-flex',
        'tw-items-center',
        'tw-rounded-6',
        'tw-gap-8',
        !isChecked && '[&&]:tw-text-label-secondary',
      )}
      onClick={() => onChange(!isChecked)}
    >
      <Icon
        kind={isChecked ? 'checkmark' : 'blank'}
        className="tw-border tw-text-[inherit] tw-rounded-6 tw-surface-elevated"
      />
      <span className="tw-flex-1 tw-text-left">{name}</span>
      <span className="tw-text-label-secondary">{suctionPercentage100}%</span>
    </Button>
  );
}
