import cx from 'classnames';
import { useState } from 'react';

import {
  Modal,
  NavigationBar,
  NavigationBarButton,
  SettingsGroup,
  SettingsGroupItem,
  TextInput,
} from '@sb/design-system';
import { useToast } from '@sbrc/hooks';
import { updatePIN } from '@sbrc/services';

interface RobotFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function UpdatePINModal({
  isOpen,
  onClose,
}: RobotFormModalProps) {
  const { setToast } = useToast();

  const [currentPIN, setCurrentPIN] = useState<string>('');
  const [currentPINError, setCurrentPINError] = useState<string | undefined>();
  const [newPIN, setNewPIN] = useState<string>('');
  const [newPINError, setNewPINError] = useState<string | undefined>();
  const [confirmPIN, setConfirmPIN] = useState<string>('');
  const [confirmPINError, setConfirmPINError] = useState<string | undefined>();

  const isSubmittable = Boolean(currentPIN && newPIN && confirmPIN);

  const validate = () => {
    let isValid = true;

    if (newPIN.match(/^\d{4,}$/)) {
      setNewPINError(undefined);
    } else if (newPIN.length >= 4) {
      setNewPINError(
        'Passcode must be only numeric digits (no letters or special characters)',
      );

      isValid = false;
    } else {
      setNewPINError('Passcode must be 4 or more digits');
      isValid = false;
    }

    if (newPIN === confirmPIN) {
      setConfirmPINError(undefined);
    } else {
      setConfirmPINError('Confirmed passcode must match new passcode');
      isValid = false;
    }

    setCurrentPINError(undefined);

    return isValid;
  };

  const handleOnClose = () => {
    setCurrentPIN('');
    setCurrentPINError(undefined);
    setNewPIN('');
    setNewPINError(undefined);
    setConfirmPIN('');
    setConfirmPINError(undefined);
    onClose();
  };

  const onSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      setToast({
        kind: 'progress',
        message: 'Updating passcode',
      });

      await updatePIN(currentPIN, newPIN);

      setToast({
        kind: 'success',
        message: 'Passcode updated successfully',
      });

      handleOnClose();
    } catch (error) {
      setToast({ kind: 'error', message: 'Error updating passcode' });
      setCurrentPINError('Passcode not accepted');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <form onSubmit={onSubmitForm}>
        <NavigationBar
          contentLeft={
            <NavigationBarButton onClick={handleOnClose}>
              Cancel
            </NavigationBarButton>
          }
          contentRight={
            <NavigationBarButton type="submit" disabled={!isSubmittable}>
              Save
            </NavigationBarButton>
          }
        >
          Update passcode
        </NavigationBar>
        <div className="tw-w-512 tw-min-h-[300px] tw-px-24 tw-pb-24 tw-flex tw-flex-col tw-gap-32">
          <div>
            <h5 className="tw-heading-40 tw-px-16">Current passcode</h5>
            <SettingsGroupItem isSeparated className="tw-px-0">
              <TextInput
                className={cx(
                  'tw-w-full',
                  Boolean(currentPINError) && 'tw-outline-error',
                )}
                autoFocus
                type="password"
                placeholder="Current passcode"
                value={currentPIN}
                onChange={(value) => setCurrentPIN(value)}
              />
            </SettingsGroupItem>
            {currentPINError && (
              <p className="tw-mt-12 tw-px-16 tw-text-13 tw-text-red">
                {currentPINError}
              </p>
            )}
          </div>
          <div>
            <h5 className="tw-heading-40 tw-px-16">New passcode</h5>
            <SettingsGroup>
              <SettingsGroupItem className="tw-px-0">
                <TextInput
                  className={cx(
                    'tw-w-full',
                    Boolean(newPINError) && 'tw-outline-error',
                  )}
                  type="password"
                  placeholder="New passcode"
                  value={newPIN}
                  onChange={(value) => setNewPIN(value)}
                />
              </SettingsGroupItem>
              <SettingsGroupItem className="tw-px-0">
                <TextInput
                  className={cx(
                    'tw-w-full',
                    Boolean(confirmPINError) && 'tw-outline-error',
                  )}
                  type="password"
                  placeholder="Confirm new passcode"
                  value={confirmPIN}
                  onChange={(value) => setConfirmPIN(value)}
                />
              </SettingsGroupItem>
            </SettingsGroup>
            {newPINError && (
              <p className="tw-mt-12 tw-px-16 tw-text-13 tw-text-red">
                {newPINError}
              </p>
            )}
            {confirmPINError && (
              <p className="tw-mt-12 tw-px-16 tw-text-13 tw-text-red">
                {confirmPINError}
              </p>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
}
