import type { Integrations } from '@sb/remote-control/types';

import { AddPortButton } from './AddPortButton';
import { CustomIOEquipmentManagerContextProvider } from './CustomIOEquipmentManagerContext';
import { IOPortTable } from './IOPortTable';

export function CustomIOEquipmentManager({
  item,
  onUpdate,
  robot,
  otherEquipment,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'CustomIO') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const inputs = item.ports.filter((port) => port.kind === 'Input');
  const outputs = item.ports.filter((port) => port.kind === 'Output');

  return (
    <>
      <CustomIOEquipmentManagerContextProvider
        portKind="Input"
        assignedPorts={item.ports}
        robotIOInputs={robot.ioInputs}
        robotIOOutputs={robot.ioOutputs}
        otherEquipment={otherEquipment}
      >
        <div>
          <h5 className="tw-heading-40">Inputs</h5>
          <IOPortTable
            ports={inputs}
            onChange={(updatedPorts) =>
              onUpdate({
                ...item,
                ports: [...updatedPorts, ...outputs],
              })
            }
          />
          <AddPortButton
            label="Add input port"
            onAdd={(addedPort) =>
              onUpdate({
                ...item,
                ports: [...inputs, addedPort, ...outputs],
              })
            }
          />
        </div>
      </CustomIOEquipmentManagerContextProvider>

      <CustomIOEquipmentManagerContextProvider
        portKind="Output"
        assignedPorts={item.ports}
        robotIOInputs={robot.ioInputs}
        robotIOOutputs={robot.ioOutputs}
        otherEquipment={otherEquipment}
      >
        <div>
          <h5 className="tw-heading-40">Outputs</h5>
          <IOPortTable
            ports={outputs}
            onChange={(updatedPorts) =>
              onUpdate({
                ...item,
                ports: [...inputs, ...updatedPorts],
              })
            }
          />
          <AddPortButton
            label="Add output port"
            onAdd={(addedPort) =>
              onUpdate({
                ...item,
                ports: [...inputs, ...outputs, addedPort],
              })
            }
          />
        </div>
      </CustomIOEquipmentManagerContextProvider>
    </>
  );
}
