import { GaugeStepperDualValue } from '@sb/design-system';
import { convertMetersToMillimeterString } from '@sb/utilities';

import { OR_3FG15_DIAMETER_METERS_SLIDER_STEP } from '../../constants';

import type { DualValueModeProps } from './types';

export default function GripperDiameter({
  gripperControlState,
}: DualValueModeProps) {
  const {
    setTargetDiameter,
    diameterRange,
    currentDiameter,
    command,
    isDiameterEqual,
  } = gripperControlState;

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">
        Diameter
        <span className="tw-text-13 tw-font-regular tw-text-label-tertiary">
          15 second actuation
        </span>
      </h5>

      <GaugeStepperDualValue
        kind="Filled"
        value={currentDiameter}
        targetValue={isDiameterEqual ? undefined : command.targetDiameter}
        onChange={setTargetDiameter}
        min={diameterRange.min}
        max={diameterRange.max}
        step={OR_3FG15_DIAMETER_METERS_SLIDER_STEP}
        valueToString={convertMetersToMillimeterString}
      />
    </section>
  );
}
