import type { Integrations } from '@sb/remote-control/types';

import { IOPortRow } from './IOPortRow';

type IOPortTableProps = {
  ports: Integrations.IOPort[];
  onChange: (ports: Integrations.IOPort[]) => void;
};

export function IOPortTable({ ports, onChange }: IOPortTableProps) {
  if (ports.length === 0) {
    return null;
  }

  const onPortChange = (port: Integrations.IOPort, i: number) =>
    onChange([...ports.slice(0, i), port, ...ports.slice(i + 1)]);

  const onPortDelete = (i: number) =>
    onChange([...ports.slice(0, i), ...ports.slice(i + 1)]);

  return (
    <div className="-tw-mx-4">
      <table className="tw-border-separate tw-border-spacing-4 tw-table-fixed tw-w-full">
        <thead className="tw-text-15 tw-text-label-secondary">
          <tr>
            <th className="tw-p-0 tw-font-medium tw-text-left tw-w-84">Port</th>
            <th className="tw-p-0 tw-font-medium tw-text-left">Name</th>
            <th className="tw-p-0 tw-font-medium tw-text-left" colSpan={2}>
              Values
            </th>
          </tr>
        </thead>
        <tbody>
          {ports.map((port, i) => {
            return (
              <IOPortRow
                key={port.port}
                port={port}
                onChange={(updatedPort) => onPortChange(updatedPort, i)}
                onDelete={() => onPortDelete(i)}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
