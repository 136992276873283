interface CameraFrameThumbnailProps {
  storageID: string;
  alt: string;
  children?: React.ReactNode;
}

export function CameraFrameThumbnail({
  storageID,
  alt,
  children,
}: CameraFrameThumbnailProps) {
  return (
    <div className="tw-h-[56px] tw-w-100 tw-bg-metal-40 tw-rounded-[4px] tw-flex tw-justify-center tw-items-center tw-mr-4">
      <div className="tw-relative">
        <img
          className="tw-max-h-[60px] tw-max-w-[100px]"
          src={`/storage/${storageID}`}
          alt={alt}
        />
        {children}
      </div>
    </div>
  );
}
