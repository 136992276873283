import { SettingsGroup, SettingsGroupNavigationItem } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { useFeatureFlag } from '@sbrc/hooks';

import { AccuracyCalibration } from './AccuracyCalibration';
import { IntrinsicsCalibration } from './IntrinsicsCalibration';
import { Timestamp } from './Timestamp';

export function WristCameraEquipmentManager({
  item,
  onUpdate,
  onSave,
  isFormDisabled,
  setChildContent,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'WristCamera') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const isAccuracyCalibrationEnabled = useFeatureFlag(
    'cameraExtrinsicCalibration',
  );

  return (
    <section>
      <h5 className="tw-heading-40 tw-pl-16">Calibration</h5>

      <SettingsGroup>
        <SettingsGroupNavigationItem
          label={
            <>
              <span>Intrinsics calibration</span>
              {item.intrinsics && (
                <Timestamp
                  timestamp={item.intrinsicsCalibration.at(-1)?.timestamp}
                  dateOnly
                />
              )}
              {!item.isIntrinsicsCalibrationEnabled && (
                <span className="tw-text-label-tertiary tw-text-13">
                  (disabled)
                </span>
              )}
            </>
          }
          disabled={isFormDisabled}
          onClick={async () => {
            await onSave();

            setChildContent(
              <IntrinsicsCalibration
                initialConfiguration={item}
                onBack={() => setChildContent(null)}
                onUpdate={onUpdate}
              />,
            );
          }}
        >
          Calibrate
        </SettingsGroupNavigationItem>

        {isAccuracyCalibrationEnabled && (
          <SettingsGroupNavigationItem
            label={
              <>
                <span>Accuracy calibration</span>
                {item.isAccuracyCalibrationEnabled && (
                  <Timestamp
                    timestamp={item.accuracyCalibration.at(-1)?.timestamp}
                    dateOnly
                  />
                )}
                {!item.isAccuracyCalibrationEnabled && (
                  <span className="tw-text-label-tertiary tw-text-13">
                    (disabled)
                  </span>
                )}
              </>
            }
            disabled={isFormDisabled}
            onClick={async () => {
              await onSave();

              setChildContent(
                <AccuracyCalibration
                  initialConfiguration={item}
                  onBack={() => setChildContent(null)}
                  onUpdate={onUpdate}
                />,
              );
            }}
          >
            Calibrate
          </SettingsGroupNavigationItem>
        )}
      </SettingsGroup>
    </section>
  );
}
