import {
  Gauge,
  GaugeContainer,
  HoldableButton,
  Icon,
  NavigationBar,
} from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';
import {
  useGuidedMode,
  useRobotDynamicBaseState,
  useRobotRoutineRunningState,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';

function heightToString(meters: number) {
  return `${(meters * 1000).toFixed(0)}mm`;
}

export function EwellixLiftTLTWidget({
  isVizbot,
  implementation,
}: Integrations.WidgetProps) {
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });
  const dynamicBaseState = useRobotDynamicBaseState({ isVizbot });

  const routineRunningState = useRobotRoutineRunningState({ isVizbot });

  const isRoutineRunning = routineRunningState !== null;

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const { setToast } = useToast();

  const minHeightMeters = dynamicBaseState?.minHeightMeters ?? 0;
  const maxHeightMeters = dynamicBaseState?.maxHeightMeters ?? 0;

  const onSpinnerHold = async (isPositive: boolean) => {
    if (!dynamicBaseState) {
      return;
    }

    await runAdHocCommand({
      onRunCommand: () => {
        const targetHeight = isPositive ? maxHeightMeters : minHeightMeters;

        return routineRunnerHandle.actuateDevice({
          command: {
            kind: 'EwellixLiftTLTCommand',
            heightMeters: targetHeight,
            speedPercentage: 1,
          },
        });
      },

      onError: () => {
        setToast({ kind: 'error', message: 'Failed to actuate lift' });
      },
    });
  };

  return (
    <WidgetView>
      <NavigationBar>{implementation.getDisplayName()}</NavigationBar>

      <div className="tw-px-16 tw-pb-16 tw-flex tw-flex-col">
        <h5 className="tw-heading-40 tw-pl-16">Lift height</h5>

        <GaugeContainer>
          <HoldableButton
            className="tw-rounded-6"
            disabled={
              !dynamicBaseState ||
              isRoutineRunning ||
              dynamicBaseState.heightMeters <= minHeightMeters
            }
            onHold={() => onSpinnerHold(false)}
            onRelease={stopGuidedMode}
          >
            <Icon kind="minus" />
          </HoldableButton>
          <Gauge
            kind="Filled"
            value={dynamicBaseState?.heightMeters ?? 0}
            min={minHeightMeters}
            max={maxHeightMeters}
            valueToString={heightToString}
          />
          <HoldableButton
            className="tw-rounded-6"
            disabled={
              !dynamicBaseState ||
              isRoutineRunning ||
              dynamicBaseState.heightMeters >= maxHeightMeters
            }
            onHold={() => onSpinnerHold(true)}
            onRelease={stopGuidedMode}
          >
            <Icon kind="plus" />
          </HoldableButton>
        </GaugeContainer>
      </div>
    </WidgetView>
  );
}
