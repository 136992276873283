import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import { Menu, MenuItem, MenuSeparator, Popper } from '@sb/design-system';
import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import type { Robot } from '@sb/remote-control/types';
import type { FirmwareBuildData, RobotBadgeStatus } from '@sbrc/hooks';
import {
  useRobotGripperState,
  useFeatureFlag,
  useRobotUnbrake,
  useClearMotionPlanCache,
  useIsRobotMoving,
} from '@sbrc/hooks';
import {
  LINK_TO_BOX_PAGE,
  LINK_TO_LOGOUT_PAGE,
  LINK_TO_SCENE_PAGE,
  getLinkToRoutinePage,
} from '@sbrc/utils';

import UpdatePINModal from '../auth/UpdatePINModal';
import { DataBackupModal } from '../data-backup/DataBackupModal';
import { DeveloperAPIModal } from '../developer-api/DeveloperAPIModal';
import { EquipmentManagerModal } from '../equipment-manager';
import { FactoryResetModal } from '../factory-reset/FactoryResetModal';
import { IOManagerModal } from '../io-manager';
import RobotFormModal from '../robot-form/RobotFormModal';
import SafetySettingsModal from '../safety-settings/SafetySettingsModal';
import type { SoftwareBuildData } from '../software-update';
import { SoftwareUpdateModal } from '../software-update';
import { UserSettingsModal } from '../user-settings/UserSettingsModal';

import { DisconnectedCard } from './DisconnectedCard';
import { SpeedCard } from './SpeedCard';

type RobotDropdownModalKind =
  | 'io'
  | 'move'
  | 'pin'
  | 'userSettings'
  | 'renameRobot'
  | 'safetySettings'
  | 'dataBackups'
  | 'software'
  | 'developerAPI'
  | 'equipmentManager'
  | 'factoryReset'
  | null;

interface RobotBadgeMenuProps {
  robot: Robot.ConvertedResponse;
  isVizbot?: boolean;
  robotBadgeStatus: RobotBadgeStatus;
  buildData: SoftwareBuildData;
  firmwareBuildData: FirmwareBuildData;
}

export default function RobotBadgeMenu({
  robot,
  isVizbot,
  robotBadgeStatus,
  buildData,
  firmwareBuildData,
}: RobotBadgeMenuProps) {
  const { push } = useRouter();
  const enableDeveloperAPI = useFeatureFlag('enableDeveloperAPI');

  const clearMotionPlanCache = useClearMotionPlanCache();

  const enableFirmwareMismatch = useFeatureFlag('enableFirmwareMismatch');

  const { shouldIndicateFirmwareExcludingIOMismatch } = firmwareBuildData;

  const { brakeRobot, canBrake } = useRobotUnbrake({ isVizbot });

  const [modal, setModal] = useState<RobotDropdownModalKind>(null);

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  const gripperState = useRobotGripperState({});

  const { isRobotMoving } = useIsRobotMoving({});

  const hasEquipmentError = useMemo(() => {
    if (gripperState) {
      const integration = getEquipmentByKind(gripperState.kind);
      const summaryStatus = integration.getSummaryStatus?.(gripperState);

      return (
        summaryStatus === 'disconnected' || summaryStatus?.kind === 'error'
      );
    }

    return false;
  }, [gripperState]);

  const closeModal = () => setModal(null);

  const { latestRoutineID } = robot;

  const isFirmwareMismatched =
    enableFirmwareMismatch &&
    shouldIndicateFirmwareExcludingIOMismatch &&
    !buildData.isSoftwareUpdateAvailable;

  const softwareUpdateIndicator = (() => {
    if (buildData.isSoftwareUpdateAvailable) {
      return (
        <span className="tw-w-12 tw-h-12 tw-rounded-10 tw-inline-block tw-bg-blue tw-mr-8" />
      );
    }

    if (isFirmwareMismatched) {
      return (
        <span className="tw-w-12 tw-h-12 tw-rounded-10 tw-inline-block tw-bg-red tw-mr-8" />
      );
    }

    return null;
  })();

  return (
    <>
      <Menu className="tw-w-256">
        {robotBadgeStatus.kind === 'disconnected' && <DisconnectedCard />}

        {robotBadgeStatus.kind !== 'disconnected' && (
          <SpeedCard robot={robot} />
        )}

        <MenuItem
          onClick={() => setModal('equipmentManager')}
          iconKind="squareGrid2x2"
          secondaryIconKind={
            hasEquipmentError ? 'exclamationTriangle' : undefined
          }
          className="[&>[data-icon-kind=exclamationTriangle]]:tw-text-red"
        >
          Equipment
        </MenuItem>

        <MenuItem
          onClick={() => setModal('io')}
          iconKind="powerplug"
          disabled={!robotBadgeStatus.isConnected}
        >
          I/O manager
        </MenuItem>

        <MenuSeparator />

        <MenuItem
          disabled={!latestRoutineID}
          onClick={() =>
            latestRoutineID && push(getLinkToRoutinePage(latestRoutineID))
          }
          iconKind="pencil"
        >
          Edit routine
        </MenuItem>

        <MenuItem
          disabled={!latestRoutineID}
          onClick={() => push(LINK_TO_SCENE_PAGE)}
          iconKind="play"
        >
          Play routine in visualizer
        </MenuItem>

        <MenuSeparator />

        <Popper
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          placement="right-end"
          offset={-220}
          skidding={80}
          content={
            <Menu className="tw-w-256">
              <MenuItem
                onClick={() => setModal('safetySettings')}
                iconKind="shield"
              >
                Safety
              </MenuItem>

              <MenuItem
                onClick={() => setModal('dataBackups')}
                iconKind="clockArrowCirclePath"
              >
                Backups
              </MenuItem>

              <MenuItem
                onClick={() => setModal('software')}
                iconKind="arrowDownSquare"
                disabled={isRobotMoving}
              >
                {softwareUpdateIndicator}
                Software update
              </MenuItem>

              <MenuSeparator />

              <MenuItem
                onClick={() => setModal('userSettings')}
                iconKind="sliderHorizontal"
              >
                User settings
              </MenuItem>

              <MenuItem
                onClick={() => setModal('renameRobot')}
                iconKind="pencil"
              >
                Robot name
              </MenuItem>

              <MenuItem
                onClick={() => setModal('pin')}
                iconKind="circleGrid3x3"
              >
                Update passcode
              </MenuItem>

              <MenuSeparator />

              <MenuItem
                onClick={() => push(LINK_TO_BOX_PAGE)}
                iconKind="shippingBox"
                disabled={!robotBadgeStatus.isConnected}
              >
                Box robot
              </MenuItem>

              <MenuItem
                onClick={() => setModal('factoryReset')}
                iconKind="reset"
                disabled={buildData.isInstallingSoftware}
              >
                Factory Reset
              </MenuItem>

              <MenuItem
                onClick={() => clearMotionPlanCache()}
                iconKind="reset"
                disabled={buildData.isInstallingSoftware}
              >
                Clear Motion Plan Cache
              </MenuItem>

              {enableDeveloperAPI && (
                <MenuItem
                  onClick={() => setModal('developerAPI')}
                  iconKind="programVariable"
                >
                  Configure developer API
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  // dispatch an event that the iPad app can detect
                  document.dispatchEvent(new Event('logout'));

                  push(LINK_TO_LOGOUT_PAGE);
                }}
                iconKind="power"
                className="tw-text-red"
                data-testid="logout-button"
              >
                Logout
              </MenuItem>
            </Menu>
          }
        >
          <MenuItem
            iconKind="settings"
            onClick={(e) => {
              setIsSettingsOpen(true);
              e.stopPropagation();
            }}
          >
            {softwareUpdateIndicator}
            Settings
          </MenuItem>
        </Popper>

        <MenuItem
          disabled={!canBrake(robotBadgeStatus)}
          onClick={brakeRobot}
          className={isVizbot ? 'tw-text-blue' : 'tw-text-red'}
          iconKind="exclamationBrake"
        >
          {isVizbot ? 'Brake visualizer robot' : 'Brake robot'}
        </MenuItem>
      </Menu>

      {modal === 'io' && (
        <IOManagerModal
          isOpen={modal === 'io'}
          onClose={closeModal}
          onOpenSafetySettings={() => setModal('safetySettings')}
          robot={robot}
        />
      )}

      <EquipmentManagerModal
        isOpen={modal === 'equipmentManager'}
        onClose={closeModal}
        robot={robot}
      />

      <UserSettingsModal
        isOpen={modal === 'userSettings'}
        onClose={closeModal}
      />

      <RobotFormModal
        isOpen={modal === 'renameRobot'}
        onClose={closeModal}
        robot={robot}
      />

      <UpdatePINModal isOpen={modal === 'pin'} onClose={closeModal} />

      <SafetySettingsModal
        isOpen={modal === 'safetySettings'}
        onClose={closeModal}
        robot={robot}
      />

      <DataBackupModal
        isOpen={modal === 'dataBackups'}
        robotID={robot.id}
        onClose={closeModal}
      />

      {modal === 'software' && (
        <SoftwareUpdateModal
          isOpen={modal === 'software'}
          onClose={closeModal}
          buildData={buildData}
          firmwareBuildData={firmwareBuildData}
        />
      )}

      <DeveloperAPIModal
        isOpen={modal === 'developerAPI'}
        onClose={closeModal}
        robot={robot}
      />

      {modal === 'factoryReset' && (
        <FactoryResetModal
          isOpen={modal === 'factoryReset'}
          onClose={closeModal}
        />
      )}
    </>
  );
}
