import cx from 'classnames';
import { clamp } from 'lodash';
import { Fragment } from 'react';

import type { RegionOfInterest } from '@sb/routine-runner';

interface ResizeHandle {
  key: string;
  cellClassName: string;
  borderClassName: string;
  buttonClassName: string;
  convertXY: (x: number, y: number) => Partial<RegionOfInterest>;
}

const resizeHandles: ResizeHandle[] = [
  {
    key: 'top',
    cellClassName: 'tw-self-start',
    borderClassName: 'tw-w-28 tw-border-t-[3px]',
    buttonClassName: 'tw-cursor-ns-resize',
    convertXY: (_x, y) => ({ top: y }),
  },
  {
    key: 'bottom',
    cellClassName: 'tw-self-end',
    borderClassName: 'tw-w-28 tw-border-b-[3px]',
    buttonClassName: 'tw-cursor-ns-resize',
    convertXY: (_x, y) => ({ bottom: 1 - y }),
  },
  {
    key: 'left',
    cellClassName: 'tw-justify-self-start',
    borderClassName: 'tw-h-28 tw-border-l-[3px]',
    buttonClassName: 'tw-cursor-ew-resize',
    convertXY: (x) => ({ left: x }),
  },
  {
    key: 'right',
    cellClassName: 'tw-justify-self-end',
    borderClassName: 'tw-h-28 tw-border-r-[3px]',
    buttonClassName: 'tw-cursor-ew-resize',
    convertXY: (x) => ({ right: 1 - x }),
  },
  {
    key: 'top-left',
    cellClassName: 'tw-self-start tw-justify-self-start',
    borderClassName:
      'tw-w-20 tw-h-20 tw-border-l-[3px] tw-border-t-[3px] tw-rounded-tl-[3px]',
    buttonClassName: 'tw-cursor-nwse-resize',
    convertXY: (x, y) => ({ top: y, left: x }),
  },
  {
    key: 'top-right',
    cellClassName: 'tw-self-start tw-justify-self-end',
    borderClassName:
      'tw-w-20 tw-h-20 tw-border-r-[3px] tw-border-t-[3px] tw-rounded-tr-[3px]',
    buttonClassName: 'tw-cursor-nesw-resize',
    convertXY: (x, y) => ({ top: y, right: 1 - x }),
  },
  {
    key: 'bottom-left',
    cellClassName: 'tw-self-end tw-justify-self-start',
    borderClassName:
      'tw-w-20 tw-h-20 tw-border-l-[3px] tw-border-b-[3px] tw-rounded-bl-[3px]',
    buttonClassName: 'tw-cursor-nesw-resize',
    convertXY: (x, y) => ({ bottom: 1 - y, left: x }),
  },
  {
    key: 'bottom-right',
    cellClassName: 'tw-self-end tw-justify-self-end',
    borderClassName:
      'tw-w-20 tw-h-20 tw-border-r-[3px] tw-border-b-[3px] tw-rounded-br-[3px]',
    buttonClassName: 'tw-cursor-nwse-resize',
    convertXY: (x, y) => ({ bottom: 1 - y, right: 1 - x }),
  },
];

interface RegionOfInterestEditorOverlayProps {
  regionOfInterest: RegionOfInterest;
  onChange: (value: RegionOfInterest) => void;
}

export function RegionOfInterestEditorOverlay({
  regionOfInterest,
  onChange,
}: RegionOfInterestEditorOverlayProps) {
  const insetStyle = {
    top: `${regionOfInterest.top * 100}%`,
    bottom: `${regionOfInterest.bottom * 100}%`,
    left: `${regionOfInterest.left * 100}%`,
    right: `${regionOfInterest.right * 100}%`,
  };

  return (
    <div
      className={cx(
        'tw-absolute',
        'tw-border',
        'tw-border-white',
        'tw-grid',
        'tw-items-center',
        'tw-justify-items-center',
      )}
      style={insetStyle}
    >
      {resizeHandles.map(
        ({
          key,
          cellClassName,
          borderClassName,
          buttonClassName,
          convertXY,
        }) => (
          <Fragment key={key}>
            <div
              className={cx(
                cellClassName,
                borderClassName,
                'tw-row-start-1',
                'tw-col-start-1',
                'tw-border-white',
                'tw-m-[-3px]',
              )}
            />
            <div
              className={cx(
                cellClassName,
                buttonClassName,
                'tw-row-start-1',
                'tw-col-start-1',
                'tw-w-52',
                'tw-h-52',
                'tw-m-[-20px]',
                'tw-rounded-full',
              )}
              onPointerDown={(e) => {
                e.currentTarget.setPointerCapture(e.pointerId);
              }}
              onPointerMove={(e) => {
                if (
                  e.currentTarget.hasPointerCapture(e.pointerId) &&
                  e.currentTarget.parentElement?.parentElement
                ) {
                  const { left, width, top, height } =
                    e.currentTarget.parentElement.parentElement.getBoundingClientRect();

                  const x = clamp((e.clientX - left) / width, 0, 1);
                  const y = clamp((e.clientY - top) / height, 0, 1);
                  const minWidth = 100 / width;
                  const minHeight = 100 / height;

                  const newPartialROI = convertXY(x, y);

                  const newROI = {
                    top: clamp(
                      newPartialROI.top ?? regionOfInterest.top,
                      0,
                      1 - regionOfInterest.bottom - minHeight,
                    ),
                    right: clamp(
                      newPartialROI.right ?? regionOfInterest.right,
                      0,
                      1 - regionOfInterest.left - minWidth,
                    ),
                    left: clamp(
                      newPartialROI.left ?? regionOfInterest.left,
                      0,
                      1 - regionOfInterest.right - minWidth,
                    ),
                    bottom: clamp(
                      newPartialROI.bottom ?? regionOfInterest.bottom,
                      0,
                      1 - regionOfInterest.top - minHeight,
                    ),
                  };

                  onChange(newROI);
                }
              }}
            />
          </Fragment>
        ),
      )}
    </div>
  );
}
