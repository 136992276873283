import cx from 'classnames';
import { useState } from 'react';

import { FirmwareUpdateSelectPanel } from '@sbrc/components/software-update/FirmwareUpdateSelectPanel';
import { FirmwareVisibilityToggle } from '@sbrc/components/software-update/FirmwareVisibilityToggle';
import type { SoftwareBuildData } from '@sbrc/components/software-update/useSoftwareBuildData';

interface FirmwareUpdateSectionProps {
  buildData: SoftwareBuildData;
}

export function FirmwareUpdateSection({
  buildData,
}: FirmwareUpdateSectionProps) {
  const [showFirmwareOptions, setShowFirmwareOptions] = useState(false);

  return (
    <div className={cx('tw-flex', 'tw-flex-col', 'tw-gap-24', 'tw-mt-8')}>
      <FirmwareVisibilityToggle
        checked={showFirmwareOptions}
        onChange={(val) => setShowFirmwareOptions(val)}
      />
      {showFirmwareOptions && (
        <FirmwareUpdateSelectPanel buildData={buildData} />
      )}
    </div>
  );
}
