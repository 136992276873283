import type { RegionOfInterest } from '@sb/routine-runner';

import { getCameraStream } from './getCameraStream';

export async function getCameraStreamFrame(options?: {
  regionOfInterest?: RegionOfInterest;
}): Promise<Blob> {
  const { imageBitmap } = getCameraStream();

  if (!imageBitmap) {
    throw new Error('Stream not loaded');
  }

  const canvas = document.createElement('canvas');
  const canvasCtx = canvas.getContext('2d');

  if (!canvasCtx) {
    throw new Error('Unable to create canvas context');
  }

  if (options?.regionOfInterest) {
    const top = Math.floor(imageBitmap.height * options.regionOfInterest.top);
    const left = Math.floor(imageBitmap.width * options.regionOfInterest.left);

    const height = Math.floor(
      imageBitmap.height *
        (1 - options.regionOfInterest.top - options.regionOfInterest.bottom),
    );

    const width = Math.floor(
      imageBitmap.width *
        (1 - options.regionOfInterest.left - options.regionOfInterest.right),
    );

    canvas.height = height;
    canvas.width = width;

    canvasCtx.drawImage(
      imageBitmap,
      left,
      top,
      width,
      height,
      0,
      0,
      width,
      height,
    );
  } else {
    canvas.height = imageBitmap.height;
    canvas.width = imageBitmap.width;

    canvasCtx.drawImage(imageBitmap, 0, 0);
  }

  const result = await new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Unable to create frame image'));
      }
    }, 'image/jpeg');
  });

  return result;
}
