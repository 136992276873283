import { TabItem, Tabs } from '@sb/design-system';

import { CreateBackupTab } from './CreateBackupTab';
import { RestoreBackupTab } from './RestoreBackupTab';
import { useUSBDrive } from './useUSBDrive';

export const DataBackupModalBody = ({ robotID }: { robotID: string }) => {
  const usbData = useUSBDrive();

  return (
    <div className="tw-w-512 tw-min-h-[300px] tw-px-24 tw-pb-24 tw-pt-16">
      <Tabs tabPanelContainerClassName="tw-pt-16">
        <TabItem
          id="create"
          label="Create Backup"
          panel={<CreateBackupTab robotID={robotID} usbData={usbData} />}
        />
        <TabItem
          id="restore"
          label="Restore Backup"
          panel={<RestoreBackupTab usbData={usbData} />}
        />
      </Tabs>
    </div>
  );
};
