import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import { useContext, useState } from 'react';

import { Button, Menu, MenuItem, RichSelect } from '@sb/design-system';
import type { DBBackupData, USBDriveData } from '@sb/feathers-types';
import { Loader } from '@sb/ui/components';
import { ModalContext } from '@sb/ui/components/Modal/shared';
import { restoreFromBackup } from '@sbrc/services/feathers-client';

import { DriveStatusIcon } from './DriveStatusIcon';

export const RestoreBackupTab = ({ usbData }: { usbData: USBDriveData[] }) => {
  const { onClose } = useContext(ModalContext);
  const [isRestoring, setIsRestoring] = useState(false);

  const [selectedDriveLabel, setSelectedDriveLabel] = useState<string | null>(
    null,
  );

  const [selectedBackup, setSelectedBackup] = useState<DBBackupData>();
  const [restoreData, setRestoreData] = useState<DBBackupData | null>(null);

  const driveData =
    usbData.find((drive) => drive.label === selectedDriveLabel) ??
    usbData[0] ??
    null;

  let driveHeaderLabel: string;
  let driveHeader: ReactNode;
  let subText: string = '';
  let restorePossible = false;
  let backupList: ReactNode[] = [];

  const restoreClick = async () => {
    if (!selectedBackup) return;
    setIsRestoring(true);
    setRestoreData(null);

    try {
      const restoreResult = await restoreFromBackup(
        selectedBackup.path,
        selectedBackup.robotID,
        selectedBackup.key,
      );

      setIsRestoring(false);
      setRestoreData(restoreResult);
    } catch (err) {
      setIsRestoring(false);

      setRestoreData({
        path: selectedBackup.path,
        robotID: selectedBackup.robotID,
        key: selectedBackup.key,
        date: new Date(),
        status: 'failed',
        error: err.message,
      });
    }
  };

  if (driveData) {
    restorePossible = true;
    driveHeaderLabel = driveData.label;
    if (isRestoring) driveHeaderLabel = `Restoring from ${driveData.label}`;
    else if (restoreData?.status === 'success')
      driveHeaderLabel = `Backup restored from ${driveData.label}`;
    else if (restoreData?.status === 'failed')
      driveHeaderLabel = `Error occured while restoring from ${driveData.label}`;

    backupList = driveData.backups.map((backup) => {
      return (
        <MenuItem
          key={backup.key}
          onClick={() => setSelectedBackup(backup)}
          iconKind={
            selectedBackup?.date === backup.date ? 'checkmark' : 'blank'
          }
        >
          {dayjs(backup.date).format('YYYY-MM-DD HH:mm')}
        </MenuItem>
      );
    });

    if (backupList.length === 0) {
      backupList.push(
        <MenuItem key="empty-item" disabled>
          no backups detected.
        </MenuItem>,
      );
    }

    if (restoreData) {
      subText = restoreData.status ?? '';

      if (restoreData.status === 'failed' && restoreData.error) {
        subText = restoreData.error;
      }
    }
  } else {
    driveHeaderLabel = 'No disk insterted.';
  }

  if (usbData.length > 1 && !isRestoring && !restoreData) {
    driveHeader = (
      <RichSelect
        variant="Gray"
        className="tw-rounded-10"
        options={
          <Menu>
            {usbData.map((usb) => (
              <MenuItem
                key={usb.label}
                onClick={() => {
                  setSelectedDriveLabel(usb.label);
                  setSelectedBackup(undefined);
                }}
                iconKind={
                  driveData?.label === usb.label ? 'checkmark' : 'blank'
                }
              >
                {usb.label}
              </MenuItem>
            ))}
          </Menu>
        }
      >
        {driveData?.label ?? 'select drive...'}
      </RichSelect>
    );
  } else {
    driveHeader = (
      <p className="tw-text-15 tw-font-medium">{driveHeaderLabel}</p>
    );
  }

  return (
    <>
      <p className="tw-text-15">
        To restore from a backup, insert a thumbdrive or other USB storage
        device into the USB-A port on the bottom of the control box.
      </p>
      <div className="tw-flex tw-items-center tw-gap-12 tw-mt-16 tw-px-12 tw-py-24 tw-border tw-border-divider-primary tw-rounded-10">
        {driveData && <DriveStatusIcon status={restoreData?.status} />}
        <div className="tw-flex tw-flex-grow tw-flex-col tw-items-start tw-justify-center">
          {driveHeader}

          {!isRestoring && subText && (
            <p className="tw-text-15 tw-text-label-tertiary">{subText}</p>
          )}
        </div>
        {isRestoring && <Loader />}
        {!isRestoring && !restoreData && (
          <>
            <RichSelect
              variant="Gray"
              className="tw-rounded-10"
              disabled={!restorePossible}
              options={<Menu>{backupList}</Menu>}
            >
              {selectedBackup?.date
                ? dayjs(selectedBackup.date).format('YYYY-MM-DD HH:mm')
                : 'Choose backup...'}
            </RichSelect>
            <Button
              className="tw-rounded-10"
              variant="Filled"
              disabled={isRestoring || !restorePossible || !selectedBackup}
              onClick={restoreClick}
            >
              Restore
            </Button>
          </>
        )}
        {restoreData?.status === 'success' && (
          <Button className="tw-rounded-10" onClick={onClose}>
            Done
          </Button>
        )}
        {restoreData?.status === 'failed' && (
          <Button
            color="Red"
            className="tw-bg-red-10 dark:tw-bg-red-100 tw-rounded-10 enabled:hover:tw-brightness-90"
            onClick={restoreClick}
          >
            Retry
          </Button>
        )}
      </div>
    </>
  );
};
