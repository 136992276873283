import { ModbusFunctionCode } from '../modbus/constants';

import { modbusCodeList } from './constants';
import type {
  ModbusTCPServerRegisterKind,
  ModbusTCPRequestFunctionCode,
  ModbusTCPRegisterData,
} from './types';

export function convertRegisterTypeToDisplayName(
  dataFormat: ModbusTCPServerRegisterKind,
) {
  const registerKindDisplayName = {
    inputRegister: 'Input Register',
    holdingRegister: 'Holding Register',
    inputCoil: 'Input Coil',
    statusCoil: 'Status Coil',
  };

  return registerKindDisplayName[dataFormat];
}

export function isReadRegisterRequest(request: ModbusTCPRequestFunctionCode) {
  return request === ModbusFunctionCode.Read;
}

export function isWriteRegisterRequest(request: ModbusTCPRequestFunctionCode) {
  return request === ModbusFunctionCode.Write;
}

// Per Modbus Specs, input registers are read-only, holding registers allow read / write
// https://en.wikipedia.org/wiki/Modbus#Format_of_requests_and_responses
export function getIsWriteRegisterKind(register: ModbusTCPRegisterData) {
  return register.type === 'holdingRegister';
}

export function getIsReadRegisterKind(register: ModbusTCPRegisterData) {
  return (
    register.type === 'holdingRegister' ||
    register.type === 'statusCoil' ||
    register.type === 'inputRegister'
  );
}

export function getModbusCodeList() {
  return modbusCodeList;
}

export function convertModbusFunctionCodeDisplayName(
  code?: ModbusTCPRequestFunctionCode,
) {
  const functionCode = getModbusCodeList().find(
    (requestCode) => code === requestCode.code,
  );

  return functionCode?.name;
}

export function isCoilRegister(kind: ModbusTCPServerRegisterKind) {
  return kind === 'inputCoil' || kind === 'statusCoil';
}
