import { GaugeStepperDualValue } from '@sb/design-system';
import { convertMetersToMillimeterString } from '@sb/utilities';

import { OR_2FG7_DIAMETER_METERS_SLIDER_STEP } from '../../constants';

import type { DualValueModeProps } from './types';

export default function GripperWidth({
  gripperControlState,
}: DualValueModeProps) {
  const {
    setTargetGripWidth,
    gripWidthRange,
    isDisabled,
    currentGripWidth,
    command,
    isGripWidthEqual,
  } = gripperControlState;

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">
        Grip width
        <span className="tw-text-13 tw-text-label-tertiary tw-font-regular">
          5 second actuation
        </span>
      </h5>

      <GaugeStepperDualValue
        kind="Filled"
        value={currentGripWidth}
        targetValue={isGripWidthEqual ? undefined : command.targetDiameter}
        isDisabled={isDisabled}
        onChange={setTargetGripWidth}
        min={gripWidthRange.min}
        max={gripWidthRange.max}
        step={OR_2FG7_DIAMETER_METERS_SLIDER_STEP}
        valueToString={convertMetersToMillimeterString}
      />
    </section>
  );
}
