import { useEffect, useState } from 'react';

import { Menu } from '@sb/design-system';
import type { Notification } from '@sb/remote-control/types';

import { NotificationDetail } from './NotificationDetail';
import { NotificationList } from './NotificationList';

interface NotificationsModalProps {
  isOpen: boolean;
}

export function NotificationsModal({ isOpen }: NotificationsModalProps) {
  const [selectedNotification, setSelectedNotification] =
    useState<Notification.ConvertedResponse | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setSelectedNotification(null);
    }
  }, [isOpen]);

  return (
    <Menu className="tw-w-320 tw-h-[80vh]">
      <div className={selectedNotification ? 'tw-hidden' : 'tw-contents'}>
        <NotificationList
          isModalOpen={isOpen}
          onSelect={setSelectedNotification}
        />
      </div>

      {selectedNotification && (
        <NotificationDetail
          notification={selectedNotification}
          onBack={() => setSelectedNotification(null)}
        />
      )}
    </Menu>
  );
}
