import NextLink from 'next/link';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import {
  Button,
  Checkbox,
  Link,
  Modal,
  ModalContent,
  ModalHeader,
  Typography,
} from '@sb/ui/components';
import {
  useRobotCurrentStepID,
  useRobotData,
  useRobotPauseDetails,
  useRoutineRunnerHandle,
  useStopRobot,
  useToast,
} from '@sbrc/hooks';
import { CHECKBOX_NO_HUMANS_LABEL, getLinkToRoutinePage } from '@sbrc/utils';

import {
  getWaitForConfirmationTitle,
  WAIT_FOR_CONFIRMATION_MESSAGE_DEFAULT,
} from '../shared';

import styles from './WaitForConfirmationModal.module.css';

interface WaitForConfirmationModalProps {
  isAtRoutineEditor?: boolean;
  isVizbot?: boolean;
  robotID: string;
  shouldOpenAutomatically?: boolean;
}

export interface WaitForConfirmationModalRef {
  open: () => void;
  isWaitingForConfirmation: boolean;
}

export const WaitForConfirmationModal = forwardRef(
  (
    {
      isAtRoutineEditor,
      isVizbot,
      robotID,
      shouldOpenAutomatically,
    }: WaitForConfirmationModalProps,
    ref: React.Ref<WaitForConfirmationModalRef>,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const { setToast, toastState } = useToast();

    const { isStopping, stopRobot } = useStopRobot({ isVizbot });

    const stepID = useRobotCurrentStepID({ isVizbot });

    const robotData = useRobotData(robotID);

    const robotName = isVizbot ? 'Visualizer' : 'Live Robot';

    const pauseDetails = useRobotPauseDetails({ isVizbot });

    const waitingForConfirmationDetails = pauseDetails.find(
      (d) => d.kind === 'waitForConfirmation',
    );

    const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

    const isWaitingForConfirmation =
      waitingForConfirmationDetails !== undefined;

    useImperativeHandle(
      ref,
      () => ({
        open: () => {
          if (isWaitingForConfirmation) {
            setIsOpen(true);
          }
        },
        isWaitingForConfirmation,
      }),
      [isWaitingForConfirmation],
    );

    const onResumeClick = async () => {
      if (!stepID) {
        return;
      }

      setToast({ kind: 'progress', message: 'Resuming routine...' });

      try {
        await routineRunnerHandle.confirmStep(stepID);

        setToast({
          kind: 'success',
          message: `${robotName}: successfully resumed playing`,
        });

        setIsOpen(false);
      } catch (error) {
        setToast({ kind: 'error', message: error.message });
      }
    };

    useEffect(() => {
      // Close the modal if the routine is resumed in another device.
      if (!isWaitingForConfirmation || !stepID) {
        setIsOpen(false);
      }

      if (isWaitingForConfirmation && shouldOpenAutomatically) {
        setIsOpen(true);
      }
    }, [isWaitingForConfirmation, stepID, shouldOpenAutomatically]);

    return (
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalHeader title={getWaitForConfirmationTitle(robotName)} />

        <ModalContent className={styles.resumeMessageModalContent}>
          <Typography variant="medium">
            {waitingForConfirmationDetails?.reason ||
              WAIT_FOR_CONFIRMATION_MESSAGE_DEFAULT}
          </Typography>

          <Checkbox
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className={styles.checkbox}
          >
            {CHECKBOX_NO_HUMANS_LABEL}
          </Checkbox>
        </ModalContent>

        <ModalContent className={styles.buttonGroupModalContent}>
          <Button
            isFullWidth
            disabled={!isChecked || toastState?.kind === 'progress'}
            onClick={onResumeClick}
          >
            Resume
          </Button>

          <Button
            isFullWidth
            disabled={isStopping}
            variant="alertPrimary"
            onClick={() => stopRobot({ onSuccess: () => setIsOpen(false) })}
          >
            Stop Routine
          </Button>

          <Button
            isFullWidth
            disabled={toastState?.kind === 'progress'}
            variant="gray"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>

          {!isAtRoutineEditor && robotData?.latestRoutineID && (
            <NextLink
              href={getLinkToRoutinePage(robotData.latestRoutineID)}
              passHref
              legacyBehavior
            >
              <Link className={styles.routineEditorLink}>
                Go to Routine Editor
              </Link>
            </NextLink>
          )}
        </ModalContent>
      </Modal>
    );
  },
);
