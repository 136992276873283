import NextLink from 'next/link';

import { NavigationBar, NavigationBarBackButton } from '@sb/design-system';
import type { Notification } from '@sb/remote-control/types';
import { getLinkToRoutinePage } from '@sbrc/utils';

import { getNotificationAction, getNotificationReason } from './utils';

interface NotificationDetailProps {
  notification: Notification.ConvertedResponse;
  onBack: () => void;
}

export function NotificationDetail({
  notification,
  onBack,
}: NotificationDetailProps) {
  return (
    <>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <NavigationBarBackButton
            onClick={(e) => {
              e.stopPropagation();
              onBack();
            }}
          />
        }
      >
        Details
      </NavigationBar>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-p-16 tw-gap-12 tw-overflow-auto">
        <p className="tw-text-label-secondary tw-text-15">
          {notification.createdAt.toString()}
        </p>

        <section>
          <h5 className="tw-heading-40">Issue</h5>
          <p>{getNotificationReason(notification)}</p>
        </section>

        <section>
          <h5 className="tw-heading-40">Next steps</h5>
          <p>{getNotificationAction(notification)}</p>
        </section>

        {notification.routineID && notification.routineID !== '0' && (
          <NextLink
            href={getLinkToRoutinePage(notification.routineID)}
            className="tw-text-blue tw-underline"
          >
            Go to Routine Editor
          </NextLink>
        )}

        <section>
          <h5 className="tw-heading-40">Trace ID</h5>
          <p>{notification.failureTraceID}</p>
        </section>
      </div>
    </>
  );
}
