export const NoEquipmentEditorForm = () => {
  return (
    <section>
      <h5 className="tw-heading-40">Settings</h5>
      <p className="tw-text-label-tertiary tw-text-13">
        No settings available for this device.
      </p>
    </section>
  );
};
