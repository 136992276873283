import { Mutex, withTimeout } from 'async-mutex';
import { useEffect, useState } from 'react';

import { Slider } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import { wait } from '@sb/utilities';
import {
  useLiveRoutineRunnerHandle,
  useRobotSafeguardState,
  useVizbotRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';
import { getSafetySettings, updateRobot } from '@sbrc/services';
import {
  calculateSpeedProfile,
  ROBOT_ARM_VELOCITY_PERCENT_MAX,
  ROBOT_ARM_VELOCITY_PERCENT_MIN,
} from '@sbrc/utils';

import { MenuCardItem } from './MenuCardItem';

const updateMutex = withTimeout(new Mutex(), 5000);
const DEBOUNCE_TIME = 100;

interface SpeedCardProps {
  robot: Robot.ConvertedResponse;
}

export function SpeedCard({ robot }: SpeedCardProps) {
  const livebot = useLiveRoutineRunnerHandle();

  const vizbot = useVizbotRoutineRunnerHandle();

  const savedSpeedRestriction = Math.round(
    robot.speedRestrictionPercentage * 100,
  );

  const safetguardState = useRobotSafeguardState({});

  const { setToast } = useToast();

  const [unsavedSpeedRestriction, setUnsavedSpeedRestriction] = useState<
    number | null
  >(null);

  useEffect(() => {
    // clear unsaved changes on switching robot or on new speed limit,
    // so slider shows correct value
    setUnsavedSpeedRestriction(null);
  }, [robot.id, savedSpeedRestriction]);

  useEffect(() => {
    if (unsavedSpeedRestriction === null) {
      return undefined;
    }

    let cancelled = false;

    (async () => {
      try {
        await wait(DEBOUNCE_TIME);

        if (cancelled) {
          return;
        }

        await updateMutex.runExclusive(async () => {
          const speedRestrictionPercentage = unsavedSpeedRestriction / 100;

          await updateRobot(robot.id, {
            speedRestrictionPercentage,
          });

          /**
           * Update current routine speed
           */
          const safetySettings = await getSafetySettings(robot.id);

          const speedProfile = calculateSpeedProfile({
            safetySettings,
            speedRestrictionPercentage,
          });

          vizbot.changeRoutineSpeedProfile(speedProfile);

          const liveRoutineRunnerState = livebot.getState();

          const isPreflightTestRun =
            liveRoutineRunnerState?.kind === 'RoutineRunning' &&
            liveRoutineRunnerState.isPreflightTestRun;

          // cannot change speed profile during preflight test run
          if (!isPreflightTestRun) {
            livebot.changeRoutineSpeedProfile(speedProfile);
          }
        });
      } catch (error) {
        setToast({ kind: 'error', message: error.message });
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [unsavedSpeedRestriction, livebot, vizbot, robot.id, setToast]);

  return (
    <MenuCardItem>
      <div className="tw-flex tw-justify-between">
        <span className="tw-text-17">Speed</span>
        <span className="tw-text-label-tertiary">
          {unsavedSpeedRestriction ?? savedSpeedRestriction}%
        </span>
      </div>

      <Slider
        max={ROBOT_ARM_VELOCITY_PERCENT_MAX}
        min={ROBOT_ARM_VELOCITY_PERCENT_MIN}
        onChange={(e) =>
          setUnsavedSpeedRestriction(Number.parseInt(e.target.value, 10))
        }
        value={unsavedSpeedRestriction ?? savedSpeedRestriction}
      />

      {safetguardState === 'slowSpeed' && (
        <p className="tw-text-13 tw-text-label-tertiary">
          <strong>Slow speed is in effect</strong> due to I/O Safety Function
          Assignment configured in Safety Settings.
          {savedSpeedRestriction < 100 && (
            <>
              {' '}
              Further restricted to {savedSpeedRestriction}% of full speed
              limit.
            </>
          )}
        </p>
      )}

      {safetguardState !== 'slowSpeed' && savedSpeedRestriction < 100 && (
        <p className="tw-text-13 tw-text-label-tertiary">
          Robot is restricted to{' '}
          <strong>{savedSpeedRestriction}% of maximum</strong> defined in Safety
          Settings
        </p>
      )}
    </MenuCardItem>
  );
}
