import {
  JERK_LIMIT_DEFAULT,
  JERK_LIMIT_MAX,
  JERK_LIMIT_MIN,
} from '@sb/motion-planning';
import { NumberInput, Typography } from '@sb/ui/components';
import { useSettingValue } from '@sbrc/components/safety-settings/store';

import styles from './JerkLimit.module.css';

const JERK_LIMIT_STEP = 1.0;
const JERK_LIMIT_DECIMAL_PLACES = 1;

export function JerkLimits() {
  const [jerkLimit, setJerkLimit, onJerkLimitValidationChange, isDisabled] =
    useSettingValue('jerkLimit', (v) =>
      typeof v === 'number' ? v : JERK_LIMIT_DEFAULT,
    );

  return (
    <>
      <Typography className={styles.header} isBold variant="medium">
        Other
      </Typography>

      <Typography variant="medium">Jerk Limit</Typography>
      <NumberInput
        className={styles.field}
        disabled={isDisabled}
        decimalPlaces={JERK_LIMIT_DECIMAL_PLACES}
        value={jerkLimit}
        onChange={setJerkLimit}
        onClear={() => setJerkLimit(JERK_LIMIT_DEFAULT)}
        min={JERK_LIMIT_MIN}
        max={JERK_LIMIT_MAX}
        step={JERK_LIMIT_STEP}
        onValidationChange={onJerkLimitValidationChange}
        placeholderValue={JERK_LIMIT_DEFAULT}
      />
    </>
  );
}
