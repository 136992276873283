import cx from 'classnames';

import { Icon } from '@sb/design-system';

interface DisabledWidgetViewProps {
  contentHeader?: string;
  content: string;
  className?: string;
}

export default function DisabledWidgetBody({
  contentHeader,
  content,
  className,
}: DisabledWidgetViewProps) {
  return (
    <div
      className={cx(
        className,
        'tw-px-24',
        'tw-pb-32',
        'tw-flex',
        'tw-flex-col',
        'tw-items-center',
        'tw-gap-16',
      )}
    >
      <Icon kind="infoCircle" className="tw-text-label-secondary" />
      {contentHeader && <h4 className="tw-font-medium">{contentHeader}</h4>}
      <p
        className={cx(
          'tw-text-center',
          'tw-text-13',
          'tw-text-label-secondary',
        )}
      >
        {content}
      </p>
    </div>
  );
}
