import cx from 'classnames';

import type { SoftwareBuildData } from './useSoftwareBuildData';

interface SoftwareUpdateActivePanelProps {
  buildData: SoftwareBuildData;
}

export function SoftwareUpdateActivePanel({
  buildData,
}: SoftwareUpdateActivePanelProps) {
  const { activeBuildID, activeBuildName } = buildData;

  const regex = RegExp('.*[\\/]', 'g');

  const updatedBuildName =
    activeBuildName && regex.exec(activeBuildName)?.toString();

  const updatedBuildTitle =
    updatedBuildName && updatedBuildName === 'release/' && 'StandardOS';

  const updatedBuildDate =
    updatedBuildName &&
    updatedBuildName === 'release/' &&
    activeBuildName.replace(regex, ' ');

  return (
    <section
      className={cx('tw-pt-12', 'tw-border-t', 'tw-border-divider-primary')}
    >
      <h4
        className={cx(
          'tw-text-15',
          'tw-font-medium',
          'tw-text-label-secondary',
          'tw-mb-4',
        )}
      >
        Installed software version
      </h4>
      <p>
        {updatedBuildTitle && (
          <span className="tw-font-bold">{updatedBuildTitle}</span>
        )}
        {updatedBuildDate || activeBuildName}
      </p>
      <p className={cx('tw-text-13', 'tw-text-label-tertiary', 'tw-mt-2')}>
        {activeBuildID}
      </p>
    </section>
  );
}
