import type { DualValueModeProps } from './types';

export default function GripperError({
  gripperControlState,
}: DualValueModeProps) {
  const { linearSensorError, error, isConnected, uncalibratedError } =
    gripperControlState;

  if (!isConnected) {
    return (
      <p>
        <strong className="tw-text-red">Disconnected:</strong> Gripper does not
        appear to be connected to the arm properly.
      </p>
    );
  }

  if (linearSensorError) {
    return (
      <>
        <p>
          <strong className="tw-text-red">Error:</strong> Gripper encountered a
          linear sensor error. Please refer to OnRobot's 2FG7 documentation to
          resolve this issue.
        </p>
        <p>
          <strong>Note:</strong> This also happens when a 3FG15 is attached.
        </p>
      </>
    );
  }

  if (uncalibratedError) {
    return (
      <p>
        <strong className="tw-text-red">Error:</strong> Gripper appears to be
        uncalibrated.
      </p>
    );
  }

  if (error) {
    return (
      <p>
        <strong className="tw-text-red">Error:</strong> The 2FG7 has encountered
        an error: {error}
      </p>
    );
  }

  return null;
}
