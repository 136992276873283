import cx from 'classnames';
import { isEqual } from 'lodash';

import type { RegionOfInterest } from '@sb/routine-runner';
import { DEFAULT_REGION_OF_INTEREST } from '@sb/routine-runner';

interface CameraStreamProps {
  regionOfInterest: RegionOfInterest;
}

export function RegionOfInterestOverlay({
  regionOfInterest,
}: CameraStreamProps) {
  if (isEqual(regionOfInterest, DEFAULT_REGION_OF_INTEREST)) {
    return null;
  }

  const top = `${regionOfInterest.top * 100}%`;
  const bottom = `${100 - regionOfInterest.bottom * 100}%`;
  const left = `${regionOfInterest.left * 100}%`;
  const right = `${100 - regionOfInterest.right * 100}%`;

  // the clip path is a rectangle with a hole in it,
  // to mask the area outside of the region of interest
  const clipStyle = {
    clipPath: [
      'polygon(',
      // outer edges
      '0 0,100% 0,100% 100%, 0 100%, 0 0,',
      // inner edges
      `${left} 0,`,
      `${left} ${bottom},`,
      `${right} ${bottom},`,
      `${right} ${top},`,
      `0 ${top}`,
      ')',
    ].join(''),
  };

  return (
    <div
      className={cx('tw-absolute', 'tw-inset-0', 'tw-bg-black/40')}
      style={clipStyle}
    />
  );
}
