import { HoldableButton } from '@sb/design-system';
import {
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useRobotRoutineRunningState,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';

import type { OnRobotVGP20Command } from '../types';

interface ApplyChangesProps {
  command: OnRobotVGP20Command | null;
  isVizbot: boolean;
  resetCommand: () => void;
}

export function ApplyChangesButton({
  command,
  isVizbot,
  resetCommand,
}: ApplyChangesProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    isVizbot,
  });

  const routineRunningState = useRobotRoutineRunningState({ isVizbot });

  const isRoutineRunning = routineRunningState !== null;

  const onHoldApplyChangesButton = () => {
    if (!command) {
      return;
    }

    runAdHocCommand({
      onRunCommand: () => {
        return routineRunnerHandle.actuateDevice({ command });
      },

      onSuccess: () => {
        resetCommand();
      },

      onError: (error) => {
        setToast({
          kind: 'error',
          message: `Error controlling vacuum: ${error}`,
        });
      },
    });
  };

  return (
    <HoldableButton
      variant="Filled"
      className="tw-rounded-6 tw-m-16"
      onHold={onHoldApplyChangesButton}
      onRelease={stopGuidedMode}
      disabled={isAnotherSessionMovingRobot || isRoutineRunning || !command}
      data-testid="gripper-widget-apply-changes-button"
    >
      Hold to Apply Changes
    </HoldableButton>
  );
}
