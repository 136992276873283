import { Button, Modal, SimpleModalContent } from '@sb/design-system';

interface UnsavedChangesAlertProps {
  isOpen: boolean;
  onDiscard: () => void;
  onKeepEditing: () => void;
}

const UnsavedChangesAlert = ({
  isOpen,
  onDiscard,
  onKeepEditing,
}: UnsavedChangesAlertProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onKeepEditing}>
      <SimpleModalContent
        title="Unsaved changes"
        buttons={
          <>
            <Button className="tw-rounded-6" onClick={onKeepEditing}>
              Keep editing
            </Button>
            <Button
              variant="Filled"
              className="tw-rounded-6"
              onClick={onDiscard}
            >
              Discard changes
            </Button>
          </>
        }
      >
        Are you sure you want to leave without saving your changes?
      </SimpleModalContent>
    </Modal>
  );
};

export default UnsavedChangesAlert;
