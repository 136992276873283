import { CommandExecute } from './CommandExecute';
import GripperScrewLength from './GripperScrewLength';
import GripperShankPosition from './GripperShankPosition';
import GripperTargetForce from './GripperTargetForce';
import GripperTargetTorque from './GripperTargetTorque';
import ApplyChangesButton from './RepositionShankButton';
import type { DualValueModeProps } from './types';

export default function GripperControlDualValueMode(props: DualValueModeProps) {
  const { gripperControlState, isVizbot } = props;

  return (
    <div className="tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-16 tw-px-16 tw-pb-16">
      <GripperTargetTorque {...props} />
      <GripperScrewLength {...props} />
      <GripperTargetForce {...props} />
      <CommandExecute
        gripperControlState={gripperControlState}
        isVizbot={isVizbot}
      />
      <GripperShankPosition {...props} />
      <ApplyChangesButton {...props} />
    </div>
  );
}
