import cx from 'classnames';

import { Badge, Button, Icon, SettingsGroupItem } from '@sb/design-system';

import type { SoftwareBuildData } from './useSoftwareBuildData';

interface SoftwareUpdateTargetPanelProps {
  buildData: SoftwareBuildData;
}

export function SoftwareUpdateTargetPanel({
  buildData,
}: SoftwareUpdateTargetPanelProps) {
  const {
    activeBuildID,
    targetBuildName,
    targetBuildID,
    timeChecked,
    isSoftwareUpdateAvailable,
    isInstallingSoftware,
    onRequestSoftwareInstall,
  } = buildData;

  if (isSoftwareUpdateAvailable) {
    return (
      <>
        <SettingsGroupItem isSeparated>
          <div
            className={cx(
              'tw-py-12',
              'tw-flex',
              'tw-flex-col',
              'tw-flex-1',
              'tw-gap-2',
            )}
          >
            <p>{targetBuildName}</p>
            <p
              className={cx('tw-text-13', 'tw-text-label-tertiary', 'tw-mt-2')}
            >
              {targetBuildID}
            </p>
          </div>
          <Badge color="Green">New</Badge>
        </SettingsGroupItem>

        <Button
          variant="Filled"
          className="tw-rounded-10"
          onClick={onRequestSoftwareInstall}
          disabled={isInstallingSoftware}
        >
          <Icon kind="arrowDownSquare" />
          <span>{isInstallingSoftware ? 'Installing…' : 'Update now'}</span>
        </Button>
      </>
    );
  }

  if (activeBuildID) {
    return (
      <SettingsGroupItem isSeparated className="tw-flex-shrink-0">
        <div className={cx('tw-flex', 'tw-flex-col', 'tw-py-10')}>
          <p>This robot’s software is up-to-date</p>
          {timeChecked && (
            <p
              className={cx('tw-text-13', 'tw-text-label-tertiary', 'tw-mt-2')}
            >
              {`Last checked: ${timeChecked.toLocaleString('en-us', {
                month: 'short',
                year: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}`}
            </p>
          )}
        </div>
      </SettingsGroupItem>
    );
  }

  return (
    <SettingsGroupItem isSeparated>
      Version information is unavailable.
    </SettingsGroupItem>
  );
}
