import { useState } from 'react';

import { Icon, Menu, MenuItem, Popper } from '@sb/design-system';
import type { Notification } from '@sb/remote-control/types';

interface NotificationListMoreMenuProps {
  newIDs: string[];
  allIDs: string[];
  onStatusUpdate: (ids: string[], status: Notification.Status) => void;
}

export function NotificationListMoreMenu({
  newIDs,
  allIDs,
  onStatusUpdate,
}: NotificationListMoreMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popper
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
      placement="bottom-end"
      skidding={3}
      offset={-4}
      content={
        <Menu>
          <MenuItem
            iconKind="checkmarkCircle"
            disabled={newIDs.length === 0}
            onClick={(e) => {
              e.stopPropagation();
              onStatusUpdate(newIDs, 'viewed');
              setIsOpen(false);
            }}
          >
            Mark all as viewed
          </MenuItem>
          <MenuItem
            iconKind="box"
            disabled={allIDs.length === 0}
            onClick={(e) => {
              e.stopPropagation();
              onStatusUpdate(allIDs, 'archived');
              setIsOpen(false);
            }}
          >
            Archive all
          </MenuItem>
        </Menu>
      }
    >
      <div className="tw-p-8 tw-text-orange">
        <Icon kind="ellipsisCircle" />
      </div>
    </Popper>
  );
}
