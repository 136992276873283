import { Button, Icon, Input } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { IOBadge, IOSendSignalButton } from '@sbrc/components/io-manager';
import { useRobotIOState } from '@sbrc/hooks';

import { PortDropdown } from './PortDropdown';

type IOPortRowProps = {
  port: Integrations.IOPort;
  onChange: (port: Integrations.IOPort) => void;
  onDelete: () => void;
};

export const IOPortRow = ({ port, onChange, onDelete }: IOPortRowProps) => {
  const ioState = useRobotIOState({
    kind: port.kind,
    port: port.port,
  });

  return (
    <tr>
      <td className="tw-p-0">
        <PortDropdown
          selectedPort={port}
          onSelect={(p) => onChange({ ...port, port: p.port })}
          onUnassign={onDelete}
        >
          {(onOpen) => (
            <Button
              size={32}
              aria-label="Change port number"
              onClick={onOpen}
              className="tw-w-84 tw-px-8 tw-rounded-6 tw-text-13 tw-justify-between"
            >
              <span>
                {port.kind === 'Input' ? 'IN' : 'OUT'} {port.port}
              </span>
              <Icon kind="chevronDown" className="-tw-mr-4" />
            </Button>
          )}
        </PortDropdown>
      </td>

      <td className="tw-p-0">
        <Input
          size={32}
          className="tw-text-13 tw-w-full tw-px-8"
          aria-label={`${port.kind} port ${port.port} name`}
          value={port.name}
          onChange={(e) => onChange({ ...port, name: e.target.value })}
        />
      </td>

      <td className="tw-p-0">
        <Input
          size={32}
          className="tw-text-13 tw-w-full tw-px-8 tw-gap-6"
          value={port.highSignalName}
          onChange={(e) =>
            onChange({ ...port, highSignalName: e.target.value })
          }
          contentBefore={<IOBadge>H</IOBadge>}
          contentAfter={
            port.kind === 'Output' && (
              <IOSendSignalButton
                ioPort={port.port}
                ioLevel="high"
                disabled={ioState === 'high'}
                kind={port.kind}
              />
            )
          }
        />
      </td>

      <td className="tw-p-0">
        <Input
          size={32}
          className="tw-text-13 tw-w-full tw-px-8 tw-gap-6"
          value={port.lowSignalName}
          onChange={(e) => onChange({ ...port, lowSignalName: e.target.value })}
          contentBefore={<IOBadge>L</IOBadge>}
          contentAfter={
            port.kind === 'Output' && (
              <IOSendSignalButton
                ioPort={port.port}
                ioLevel="low"
                disabled={ioState === 'low'}
                kind={port.kind}
              />
            )
          }
        />
      </td>
    </tr>
  );
};
