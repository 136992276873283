import cx from 'classnames';
import { isEqual } from 'lodash';
import { useState } from 'react';

import {
  Icon,
  Modal,
  NavigationBar,
  NavigationBarButton,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import type { RegionOfInterest } from '@sb/routine-runner';
import { DEFAULT_REGION_OF_INTEREST } from '@sb/routine-runner';

import { CameraStream } from '../stream/CameraStream';

import { RegionOfInterestEditorOverlay } from './RegionOfInterestEditorOverlay';
import { RegionOfInterestOverlay } from './RegionOfInterestOverlay';

interface InputRegionOfInterestProps {
  isDisabled?: boolean;
  value: RegionOfInterest;
  onChange: (value: RegionOfInterest) => void;
}

export function InputRegionOfInterest({
  isDisabled,
  value,
  onChange,
}: InputRegionOfInterestProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [regionOfInterest, setRegionOfInterest] = useState(
    DEFAULT_REGION_OF_INTEREST,
  );

  return (
    <>
      <SettingsGroupNavigationItem
        label="Region of interest"
        onClick={() => {
          setRegionOfInterest(value);
          setIsModalOpen(true);
        }}
        disabled={isDisabled}
      >
        {!value && (
          <Icon
            kind="circleSlash"
            className={cx(!isDisabled && 'tw-text-label-secondary')}
          />
        )}

        <span className={cx(!isDisabled && 'tw-text-label-secondary')}>
          {isEqual(value, DEFAULT_REGION_OF_INTEREST) ? 'None Set' : 'Set'}
        </span>
      </SettingsGroupNavigationItem>

      <Modal
        isOpen={isModalOpen}
        /* don't close on click outside */
        onClose={() => {}}
        className={cx('tw-w-[90vw]', 'tw-h-[80vh]', 'tw-dark')}
      >
        <NavigationBar
          className="tw-flex-none"
          contentLeft={
            <NavigationBarButton onClick={() => setIsModalOpen(false)}>
              Cancel
            </NavigationBarButton>
          }
          contentRight={
            <>
              <NavigationBarButton
                onClick={() => {
                  onChange(DEFAULT_REGION_OF_INTEREST);
                  setIsModalOpen(false);
                }}
                disabled={
                  isDisabled || isEqual(value, DEFAULT_REGION_OF_INTEREST)
                }
              >
                Clear
              </NavigationBarButton>
              <NavigationBarButton
                onClick={() => {
                  onChange(regionOfInterest);
                  setIsModalOpen(false);
                }}
                disabled={
                  isDisabled ||
                  isEqual(regionOfInterest, DEFAULT_REGION_OF_INTEREST) ||
                  isEqual(value, regionOfInterest)
                }
              >
                Crop
              </NavigationBarButton>
            </>
          }
        >
          Select region of interest
        </NavigationBar>
        <div
          className={cx(
            'tw-flex-1',
            'tw-h-0',
            'tw-m-24',
            'tw-mt-8',
            'tw-flex',
            'tw-items-center',
            'tw-justify-center',
          )}
        >
          <CameraStream>
            <RegionOfInterestOverlay regionOfInterest={regionOfInterest} />
            <RegionOfInterestEditorOverlay
              regionOfInterest={regionOfInterest}
              onChange={setRegionOfInterest}
            />
          </CameraStream>
        </div>
      </Modal>
    </>
  );
}
