import type { JointStatusKind } from '@sbrc/hooks';

export function getFirmwareInstallIcon(status: JointStatusKind) {
  return (
    {
      installing: 'arrowCirclePath',
      requiresUpdate: 'exclamationTriangle',
      upToDate: 'checkmarkCircle',
      failed: 'exclamationTriangle',
    } as const
  )[status];
}

export function getFirmwareInstallColor(status: JointStatusKind) {
  return {
    installing: 'tw-text-blue-50',
    requiresUpdate: 'tw-text-yellow-50',
    upToDate: 'tw-text-green',
    failed: 'tw-text-red-50',
  }[status];
}

export function getFirmwareInstallText(status: JointStatusKind) {
  return {
    installing: 'Installing',
    requiresUpdate: 'Requires Update',
    upToDate: 'Up to Date',
    failed: 'Failed',
  }[status];
}
