import { useEffect } from 'react';

import { NavigationBar } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';

import type { OnRobotScrewdriverState } from '../types';

import GripperControlDualValueMode from './dual-value-mode/GripperControlDualValueMode';
import { useGripperControlState } from './useGripperControlState';

interface OnRobotScrewdriverWidgetInnerProps {
  routineRunnerGripperState: OnRobotScrewdriverState;
  args: Integrations.WidgetProps;
}

export function OnRobotScrewdriverWidgetInner({
  routineRunnerGripperState,
  args,
}: OnRobotScrewdriverWidgetInnerProps) {
  const {
    isVizbot,
    onCommandChange = () => {},
    defaultCommand,
    implementation,
  } = args;

  const gripperControlState = useGripperControlState({
    isVizbot,
    routineRunnerGripperState,
    defaultCommand:
      defaultCommand?.kind === 'OnRobotScrewdriverCommand'
        ? defaultCommand
        : undefined,
  });

  const { command } = gripperControlState;

  useEffect(() => {
    onCommandChange(command);
  }, [command, onCommandChange]);

  return (
    <WidgetView data-testid="onrobot-screwdriver-control-widget">
      <NavigationBar>{implementation.getDisplayName()}</NavigationBar>
      <GripperControlDualValueMode
        gripperControlState={gripperControlState}
        isVizbot={isVizbot}
        selectedCommandKind={command.commandKind}
      />
    </WidgetView>
  );
}
