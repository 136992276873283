import { useCallback } from 'react';

import { Button, Icon } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import type { IOLevel } from '@sb/routine-runner';
import { useIsRobotConnected, useSetIO } from '@sbrc/hooks';

interface IOSendSignalButtonProps {
  className?: string;
  disabled: boolean;
  ioLevel: IOLevel;
  ioPort: number;
  isVizbot?: boolean;
  kind: Robot.IOPortKind;
}

const IOSendSignalButton = ({
  className,
  disabled,
  ioLevel,
  ioPort,
  isVizbot,
  kind,
}: IOSendSignalButtonProps) => {
  const { setInputIO, setOutputIO } = useSetIO({ isVizbot });

  const isConnected = useIsRobotConnected({ isVizbot });

  const isSendSignalButtonDisabled = !isConnected || disabled;

  const onSetIO = useCallback(() => {
    if (kind === 'Input') {
      setInputIO(ioPort, ioLevel);
    }

    if (kind === 'Output') {
      setOutputIO(ioPort, ioLevel);
    }
  }, [ioLevel, ioPort, kind, setInputIO, setOutputIO]);

  return (
    <Button
      className={className}
      variant="Flat"
      disabled={isSendSignalButtonDisabled}
      onClick={onSetIO}
    >
      <Icon kind="arrowSquarePath" />
    </Button>
  );
};

export default IOSendSignalButton;
