import cx from 'classnames';
import { useRef } from 'react';

import { Button, Icon } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import { useIsRobotPaused, usePlayRobot, useRobotStateKind } from '@sbrc/hooks';

import type { WaitForConfirmationModalRef } from '../notifications';
import { WaitForConfirmationModal } from '../notifications';

interface PlayPauseButtonProps {
  robot: Robot.ConvertedResponse;
}

export function PlayPauseButton({ robot }: PlayPauseButtonProps) {
  // routine data
  const routineRunnerKind = useRobotStateKind({});
  const isPaused = useIsRobotPaused({});
  const isRoutinePlaying = routineRunnerKind === 'RoutineRunning' && !isPaused;

  const { onPlay, onPause } = usePlayRobot({ robotID: robot.id });

  const waitForConfirmationModalRef = useRef<WaitForConfirmationModalRef>(null);

  const onPauseButtonClick = () => {
    if (waitForConfirmationModalRef.current?.isWaitingForConfirmation) {
      waitForConfirmationModalRef.current.open();

      return;
    }

    if (isRoutinePlaying) {
      onPause();

      return;
    }

    onPlay();
  };

  return (
    <>
      <Button
        className={cx(
          'tw-flex-none',
          'tw-min-w-[105px]',
          'enabled:tw-text-label-primary',
        )}
        disabled={routineRunnerKind !== 'RoutineRunning'}
        onClick={onPauseButtonClick}
        data-testid="app-header-play-pause-button"
      >
        <Icon kind={isRoutinePlaying ? 'pause' : 'play'} />
        <span>{isRoutinePlaying ? 'Pause' : 'Play'}</span>
      </Button>

      <WaitForConfirmationModal
        ref={waitForConfirmationModalRef}
        robotID={robot.id}
        shouldOpenAutomatically
      />
    </>
  );
}
