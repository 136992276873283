import { GaugeStepperDualValue } from '@sb/design-system';
import {
  zPositionStringConvert,
  SHANK_POSITION_RANGE,
} from '@sb/integrations/OnRobotScrewdriver';

import type { DualValueModeProps } from './types';

export default function GripperShankPosition({
  gripperControlState,
}: DualValueModeProps) {
  const { changeShankPosition, actual, target } = gripperControlState;

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Z axis position</h5>
      <GaugeStepperDualValue
        value={actual.routineRunnerShankPosition}
        targetValue={
          target.targetShankPosition === actual.routineRunnerShankPosition
            ? undefined
            : target.targetShankPosition
        }
        onChange={changeShankPosition}
        {...SHANK_POSITION_RANGE}
        valueToString={zPositionStringConvert}
      />
    </section>
  );
}
