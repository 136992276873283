import { useCallback, useEffect, useState } from 'react';

import { NavigationBar, NavigationList } from '@sb/design-system';
import type { Notification } from '@sb/remote-control/types';
import { Loader } from '@sb/ui/components';
import { useAuthentication, useToast } from '@sbrc/hooks';
import { getNotifications, updateNotificationStatus } from '@sbrc/services';

import { EmptyState } from './EmptyState';
import { NotificationListItem } from './NotificationListItem';
import { NotificationListMoreMenu } from './NotificationListMoreMenu';

interface NotificationListProps {
  isModalOpen: boolean;
  onSelect: (notification: Notification.ConvertedResponse) => void;
}

export function NotificationList({
  isModalOpen,
  onSelect,
}: NotificationListProps) {
  const [isLoading, setIsLoading] = useState(true);

  const [viewedIDs, setViewedIDs] = useState<string[]>([]);

  const { userID } = useAuthentication();

  const [newNotifications, setNewNotifications] = useState<
    Notification.ConvertedResponse[]
  >([]);

  const [viewedNotifications, setViewedNotifications] = useState<
    Notification.ConvertedResponse[]
  >([]);

  const { setToast } = useToast();

  const readData = useCallback(async () => {
    if (!isModalOpen || !userID) {
      return;
    }

    try {
      const results = await Promise.all([
        getNotifications(userID, 'new'),
        getNotifications(userID, 'viewed'),
      ]);

      setNewNotifications(results[0]);
      setViewedNotifications(results[1]);
      setViewedIDs([]);
      setIsLoading(false);
    } catch (error) {
      setToast({ kind: 'error', message: error.message });
    }
  }, [isModalOpen, userID, setToast]);

  useEffect(() => {
    readData();
  }, [readData]);

  const handleUpdateStatus = async (
    ids: string[],
    status: Notification.Status,
  ) => {
    if (userID) {
      try {
        await updateNotificationStatus(ids, userID, status);
      } catch (error) {
        setToast({ kind: 'error', message: error.message });
      }
    }
  };

  return (
    <>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentRight={
          <NotificationListMoreMenu
            newIDs={newNotifications
              .map((n) => n.id)
              .filter((id) => !viewedIDs.includes(id))}
            allIDs={[...newNotifications, ...viewedNotifications].map(
              (n) => n.id,
            )}
            onStatusUpdate={async (ids, status) => {
              await handleUpdateStatus(ids, status);
              await readData();
            }}
          />
        }
      >
        Notifications
      </NavigationBar>

      <div className="tw-flex tw-flex-col tw-flex-1 tw-pb-16 tw-overflow-auto">
        {isLoading && (
          <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center">
            <Loader />
          </div>
        )}

        {!isLoading &&
          newNotifications.length === 0 &&
          viewedNotifications.length === 0 && <EmptyState />}

        {newNotifications.length > 0 && (
          <>
            <header className="tw-heading-40 tw-px-16 tw-flex-none">
              <span>New</span>
              <span className="tw-text-13 tw-text-label-tertiary">
                ({newNotifications.length})
              </span>
            </header>
            <NavigationList className="tw-flex-none">
              {newNotifications.map((n) => (
                <NotificationListItem
                  key={n.id}
                  notification={n}
                  isViewed={viewedIDs.includes(n.id)}
                  onSelect={(e) => {
                    e.stopPropagation();
                    onSelect(n);
                    setViewedIDs([...viewedIDs, n.id]);
                    handleUpdateStatus([n.id], 'viewed');
                  }}
                />
              ))}
            </NavigationList>
          </>
        )}
        {viewedNotifications.length > 0 && (
          <>
            {newNotifications.length > 0 && (
              <header className="tw-heading-40 tw-px-16 tw-flex-none tw-mt-24">
                Earlier
              </header>
            )}
            <NavigationList className="tw-flex-none">
              {viewedNotifications.map((n) => (
                <NotificationListItem
                  key={n.id}
                  notification={n}
                  isViewed
                  onSelect={(e) => {
                    e.stopPropagation();
                    onSelect(n);
                  }}
                />
              ))}
            </NavigationList>
          </>
        )}
      </div>
    </>
  );
}
