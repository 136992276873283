import cx from 'classnames';

type Props = {
  status: {
    isBusy: boolean;
    isGripDetected: boolean;
    isForceGripDetected: boolean;
  };
};

export function GripperStatus({ status }: Props) {
  const statusDisplays = [
    ['Busy', status.isBusy],
    ['Gripped', status.isGripDetected],
    ['Force Gripped', status.isForceGripDetected],
  ] as const;

  return (
    <div className="tw-flex-none tw-flex tw-justify-between tw-px-16 tw-py-8">
      {statusDisplays.map(([title, isOn]) => (
        <div key={title} className="tw-flex tw-items-center">
          <div
            className={cx(
              'tw-w-10',
              'tw-h-10',
              'tw-rounded-full',
              'tw-mr-10',
              isOn ? 'tw-bg-green' : 'tw-bg-label-quaternary',
            )}
          />
          <span
            className={cx('tw-text-13', !isOn && 'tw-text-label-quaternary')}
          >
            {title}
          </span>
        </div>
      ))}
    </div>
  );
}
