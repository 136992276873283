import { Icon } from '@sb/design-system';

export function EmptyState() {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-12 tw-min-h-full">
      <Icon kind="bell" className="tw-text-label-secondary tw-icon-42" />
      <p>No notifications</p>
    </div>
  );
}
