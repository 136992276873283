import { sortBy } from 'lodash';

import { Menu, MenuItem } from '@sb/design-system';
import type { DeviceKind } from '@sb/integrations/device';
import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import {
  canAddIntegration,
  getEquipmentList,
} from '@sb/integrations/frontend/util';
import type { Equipment } from '@sb/remote-control/types';
import { useFeatureFlag } from '@sbrc/hooks';

interface NewEquipmentPickerProps {
  onAddEquipment: (equipment: Equipment.EditableFields) => void;
  equipment: Equipment.ConvertedResponse[];
}

export function NewEquipmentPicker({
  onAddEquipment,
  equipment,
}: NewEquipmentPickerProps) {
  const isCameraEnabled = useFeatureFlag('camera');

  const availableDeviceKinds = getEquipmentList();

  const sortedAvailableEquipment = sortBy(availableDeviceKinds, (eq) =>
    canAddIntegration(eq, equipment, getEquipmentByKind) ? 0 : 1,
  );

  const onAdd = (kindToAdd: DeviceKind) => {
    const isEnabled = canAddIntegration(
      kindToAdd,
      equipment,
      getEquipmentByKind,
    );

    const int = getEquipmentByKind(kindToAdd);

    const baseName = int.getShortName();

    const getDefaultName = (i = 1): string => {
      const name = i === 1 ? baseName : `${baseName} (${i})`;

      return equipment.some((e) => e.config.name === name)
        ? getDefaultName(i + 1)
        : name;
    };

    onAddEquipment({
      config: {
        ...int.getDefaultItem(),
        name: getDefaultName(),
      },
      isEnabled,
    });
  };

  return (
    <Menu className="tw-max-h-[90vh]">
      {sortedAvailableEquipment.map((deviceKind) => {
        if (!isCameraEnabled && deviceKind === 'WristCamera') {
          return null;
        }

        const equipmentFrontendImplementation = getEquipmentByKind(deviceKind);

        return (
          <MenuItem
            key={deviceKind}
            iconKind={equipmentFrontendImplementation.iconKind}
            onClick={() => onAdd(deviceKind)}
          >
            {equipmentFrontendImplementation.getDisplayName()}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
