import type { Integrations } from '@sb/remote-control/types';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';

import { NoGripperConfiguration } from './types';

export const noGripperImplementation: Integrations.IntegrationFrontend<'NoGripper'> =
  {
    ...getGripperImplementationDefaults(),
    iconKind: 'circleSlash',
    getSupportedSteps: () => [],
    getManufacturerName: () => 'None',
    getShortName: () => 'No gripper',
    getDisplayName: () => {
      return 'No gripper';
    },
    getDefaultItem: () => NoGripperConfiguration.parse({ kind: 'NoGripper' }),
    getIntegrationVariables: () => [],
  };
