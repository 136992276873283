import { useCallback, useState } from 'react';

import {
  Button,
  Modal,
  SimpleModalContent,
  TextInput,
} from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import { useToast } from '@sbrc/hooks';
import { createRobot, updateRobot } from '@sbrc/services';
import { isDuplicateName } from '@sbrc/utils';

interface RobotFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  robot?: Robot.ConvertedResponse;
}

export default function RobotFormModal({
  isOpen,
  onClose,
  robot,
}: RobotFormModalProps) {
  const { setToast } = useToast();

  const existingRobotName = robot?.name ?? '';

  const [name, setName] = useState<string>(existingRobotName);

  const isValidNameLength = Boolean(name.trim().length);

  const isCurrentName = isDuplicateName(existingRobotName, name);

  const isDisabled = !isValidNameLength || isCurrentName;

  const onSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();

    const changes = { name };

    const request = robot
      ? updateRobot(robot.id, changes)
      : createRobot(changes);

    try {
      setToast({
        kind: 'progress',
        message: `${robot ? 'Editing' : 'Creating'} robot...`,
      });

      await request;

      setToast({
        kind: 'success',
        message: `Robot successfully ${robot ? 'edited' : 'created'}!`,
      });

      onClose();
    } catch (error) {
      setToast({ kind: 'error', message: error.message });
    }
  };

  const handleOnClose = useCallback(() => {
    setName(existingRobotName);
    onClose();
  }, [onClose, existingRobotName]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      data-testid={`${robot ? 'rename' : 'name'}-robot`}
    >
      <form onSubmit={onSubmitForm}>
        <SimpleModalContent
          title={`${robot ? 'Rename' : 'Name'} robot`}
          buttons={
            <>
              <Button
                className="tw-rounded-6"
                data-testid={`${robot ? 'rename' : 'name'}-robot-cancel-button`}
                onClick={handleOnClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="Filled"
                className="tw-rounded-6"
                data-testid={`${robot ? 'rename' : 'name'}-robot-button`}
                disabled={isDisabled}
              >
                OK
              </Button>
            </>
          }
        >
          <p>Enter a new name for your robot.</p>
          <TextInput
            className="tw-mt-16"
            placeholder="Robot name"
            value={name}
            onChange={(value) => {
              setName(value);
            }}
          />
        </SimpleModalContent>
      </form>
    </Modal>
  );
}
