import { useState } from 'react';

import {
  Icon,
  NavigationBar,
  NavigationBarBackButton,
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupSwitchItem,
} from '@sb/design-system';
import * as log from '@sb/log';
import {
  CHESSBOARD_PARAMS,
  CameraStream,
  ChessboardOverlay,
  uploadCameraFrame,
  useVisionChessboardCorners,
} from '@sbrc/components/camera';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import type { WristCameraConfiguration } from '../types';

import { CaptureCalibrationButton } from './CaptureCalibrationButton';
import { IntrinsicsInfoTooltip } from './IntrinsicsInfoTooltip';
import { Timestamp } from './Timestamp';

const MIN_CALIBRATION_ENTRIES = 6;

interface IntrinsicsCalibrationProps {
  initialConfiguration: WristCameraConfiguration;
  onBack: () => void;
  onUpdate: (calibration: WristCameraConfiguration) => void;
}

export function IntrinsicsCalibration({
  initialConfiguration,
  onBack,
  onUpdate,
}: IntrinsicsCalibrationProps) {
  const [configuration, setConfiguration] = useState(initialConfiguration);
  const [isCapturing, setIsCapturing] = useState(false);
  const routineRunnerHandle = useRoutineRunnerHandle({});

  const updateIntrinsicsCalibration = async (
    intrinsicsCalibration: WristCameraConfiguration['intrinsicsCalibration'],
  ) => {
    const newConfiguration: WristCameraConfiguration = {
      ...configuration,
      intrinsicsCalibration,
      intrinsics: null,
    };

    if (intrinsicsCalibration.length >= MIN_CALIBRATION_ENTRIES) {
      try {
        const result = await routineRunnerHandle.runVisionMethod({
          method: 'calculateIntrinsics',
          calibration: intrinsicsCalibration,
          chessboard: CHESSBOARD_PARAMS,
        });

        if (result.method === 'calculateIntrinsics') {
          newConfiguration.intrinsics = result.results.intrinsics;

          newConfiguration.intrinsicsWristToCameraTransform =
            result.results.wristToCameraTransform;
        }
      } catch (error) {
        log.error(
          'IntrinsicsCalibration.error',
          'could not calculate intrinsics',
          { error },
        );
      }
    }

    setConfiguration(newConfiguration);
    onUpdate(newConfiguration);
  };

  const updateIsIntrinsicsCalibrationEnabled = (
    isIntrinsicsCalibrationEnabled: boolean,
  ) => {
    const newConfiguration = {
      ...configuration,
      isIntrinsicsCalibrationEnabled,
    };

    setConfiguration(newConfiguration);
    onUpdate(newConfiguration);
  };

  const { isDetected, chessboardCorners } = useVisionChessboardCorners({
    isOpen: !isCapturing,
    onCapture: () => {},
  });

  const isMoreEntriesRequired =
    configuration.intrinsicsCalibration.length < MIN_CALIBRATION_ENTRIES;

  return (
    <>
      <NavigationBar contentLeft={<NavigationBarBackButton onClick={onBack} />}>
        Intrinsics calibration
      </NavigationBar>

      <div className="tw-flex-1 tw-overflow-auto tw-px-24 tw-pb-24 tw-flex tw-flex-col tw-gap-32">
        <div className="tw-max-w-320 tw-min-h-[128px] tw-self-center tw-rounded-10 tw-overflow-hidden">
          <CameraStream>
            {chessboardCorners && (
              <ChessboardOverlay points={chessboardCorners} />
            )}
          </CameraStream>
        </div>

        <SettingsGroup>
          <SettingsGroupSwitchItem
            label="Enable instrinsics calibration"
            checked={configuration.isIntrinsicsCalibrationEnabled}
            onChange={(e) =>
              updateIsIntrinsicsCalibrationEnabled(e.target.checked)
            }
          />
          <CaptureCalibrationButton
            isFound={isDetected}
            isCapturing={isCapturing}
            setIsCapturing={setIsCapturing}
            onCapture={async () => {
              const wristPose =
                routineRunnerHandle.getState()?.kinematicState.wristPose;

              if (!wristPose) {
                throw new Error('Arm pose is not available');
              }

              const storageID = await uploadCameraFrame();

              const intrinsicsCalibration = [
                ...configuration.intrinsicsCalibration,
              ];

              intrinsicsCalibration.push({
                wristPose,
                storageID,
                timestamp: new Date().toISOString(),
              });

              await updateIntrinsicsCalibration(intrinsicsCalibration);
            }}
          />
        </SettingsGroup>

        <section className="tw-flex tw-flex-col">
          <h5 className="tw-heading-40 tw-pl-16">
            <span>Calibration entries</span>
            <span className="tw-font-regular tw-text-15 tw-text-label-tertiary">
              ({configuration.intrinsicsCalibration.length})
            </span>
          </h5>

          <div className="tw-flex tw-flex-col tw-gap-8">
            {configuration.intrinsicsCalibration.map(
              (calibrationEntry, index) => {
                const name = `Calibration ${index + 1}`;

                return (
                  <SettingsGroupItem
                    key={name}
                    isSeparated
                    className="tw-pl-6 tw-py-6"
                  >
                    <div className="tw-h-[56px] tw-w-100 tw-bg-metal-40 tw-rounded-[4px] tw-flex tw-justify-center tw-items-center tw-mr-4">
                      <img
                        src={`/storage/${calibrationEntry.storageID}`}
                        alt={name}
                      />
                    </div>

                    <span>{name}</span>
                    <Timestamp timestamp={calibrationEntry.timestamp} />
                    <Icon
                      className="tw-text-red tw-icon-22"
                      kind="trash"
                      aria-disabled={isCapturing}
                      onClick={async () => {
                        setIsCapturing(true);

                        const newIntrinsicsCalibration = [
                          ...configuration.intrinsicsCalibration,
                        ];

                        newIntrinsicsCalibration.splice(index, 1);

                        await updateIntrinsicsCalibration(
                          newIntrinsicsCalibration,
                        );

                        setIsCapturing(false);
                      }}
                    />
                  </SettingsGroupItem>
                );
              },
            )}
          </div>

          <p className="tw-pl-16 tw-mt-8 tw-text-13 tw-text-label-tertiary">
            {isMoreEntriesRequired &&
              `${MIN_CALIBRATION_ENTRIES} calibration entries are required to calculate intrinsics.`}

            {!isMoreEntriesRequired && configuration.intrinsics === null && (
              <span className="tw-pl-16tw-text-red">
                Intrinsics calibration could not be calculated.
              </span>
            )}

            {!isMoreEntriesRequired && configuration.intrinsics && (
              <>
                <span>Intrinsics calculated.</span>
                <IntrinsicsInfoTooltip
                  intrinsics={configuration.intrinsics}
                  wristToCameraTransform={
                    configuration.intrinsicsWristToCameraTransform
                  }
                />
              </>
            )}
          </p>
        </section>
      </div>
    </>
  );
}
