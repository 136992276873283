import { useState } from 'react';

import { Button, Popper } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import {
  useFirmwareUpdateStatus,
  useRobotBadgeStatus,
  useSoftwareUpdateData,
} from '@sbrc/hooks';

import type { SoftwareBuildData } from '../software-update';

import RobotBadgeContent from './RobotBadgeContent';
import RobotBadgeMenu from './RobotBadgeMenu';

interface RobotBadgeDropdownProps {
  isDisabled?: boolean;
  robot: Robot.ConvertedResponse;
  isVizbot?: boolean;
  buildData: SoftwareBuildData;
}

export function RobotBadge({
  isDisabled,
  robot,
  isVizbot,
  buildData,
}: RobotBadgeDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const robotBadgeStatus = useRobotBadgeStatus();

  const softwareUpdateDataPoll1Second = useSoftwareUpdateData(1_000);

  const firmwareBuildData = useFirmwareUpdateStatus(
    softwareUpdateDataPoll1Second,
  );

  return (
    <Popper
      className="tw-w-256 tw-flex"
      placement="bottom-start"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={
        <RobotBadgeMenu
          robot={robot}
          isVizbot={isVizbot}
          robotBadgeStatus={robotBadgeStatus}
          buildData={buildData}
          firmwareBuildData={firmwareBuildData}
        />
      }
      data-testid="robot-badge"
    >
      <Button
        className="tw-flex-1"
        disabled={isDisabled}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="robot-badge-button"
      >
        <RobotBadgeContent
          robot={robot}
          robotBadgeStatus={robotBadgeStatus}
          buildData={buildData}
          firmwareBuildData={firmwareBuildData}
        />
      </Button>
    </Popper>
  );
}
