import { useEffect, useState } from 'react';

export function useCameraStreamCanvasSize(
  overlayRef: React.RefObject<Element>,
) {
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const canvasEl =
      overlayRef.current?.parentElement?.getElementsByTagName<'canvas'>(
        'canvas',
      )[0];

    if (canvasEl) {
      const updateCanvasSize = () => {
        setCanvasSize({ width: canvasEl.width, height: canvasEl.height });
      };

      updateCanvasSize();

      const m = new MutationObserver(updateCanvasSize);

      m.observe(canvasEl, { attributes: true });

      return () => m.disconnect();
    }

    return undefined;
  }, [overlayRef]);

  return canvasSize;
}
