import cx from 'classnames';

import {
  NavigationBar,
  NavigationBarButton,
  NavigationBarMenuButton,
  NavigationList,
} from '@sb/design-system';
import type { Equipment, Integrations } from '@sb/remote-control/types';

import { EquipmentManagerItem } from './EquipmentManagerItem';

interface EquipmentListProps {
  equipment: Equipment.ConvertedResponse[];
  getSummaryStatus: (
    equipmentItem: Equipment.ConvertedResponse,
  ) => Integrations.SummaryStatus;
  newEquipmentPicker: React.ReactNode;
  onClose: () => void;
  onSelect: (equipmentItem: Equipment.ConvertedResponse) => void;
}

export function EquipmentList({
  equipment,
  getSummaryStatus,
  newEquipmentPicker,
  onClose,
  onSelect,
}: EquipmentListProps) {
  return (
    <>
      <NavigationBar
        className="tw-mb-8"
        contentLeft={
          <NavigationBarButton onClick={onClose}>Done</NavigationBarButton>
        }
        contentRight={
          <NavigationBarMenuButton
            data-testid="equipment-list-add"
            content={newEquipmentPicker}
            iconKind="plus"
          />
        }
      >
        Equipment
      </NavigationBar>

      <NavigationList
        className={cx('tw-flex-1', 'tw-overflow-auto', 'tw-pb-24', 'tw-px-8')}
      >
        {equipment.map((equipmentItem) => {
          return (
            <EquipmentManagerItem
              key={equipmentItem.id}
              summaryStatus={getSummaryStatus(equipmentItem)}
              equipment={equipmentItem}
              name={equipmentItem.config.name}
              onClick={() => onSelect(equipmentItem)}
            />
          );
        })}
      </NavigationList>
    </>
  );
}
