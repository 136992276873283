interface TimestampProps {
  timestamp?: string;
  dateOnly?: boolean;
}

export function Timestamp({ timestamp, dateOnly }: TimestampProps) {
  if (!timestamp) {
    return null;
  }

  const date = new Date(timestamp);

  if (Number.isNaN(date.valueOf())) {
    return null;
  }

  return (
    <span className="tw-text-label-tertiary tw-text-13 tw-flex-1">
      {dateOnly ? date.toLocaleDateString() : date.toLocaleString()}
    </span>
  );
}
