import {
  SettingsGroup,
  SettingsGroupSliderItem,
  SettingsGroupSwitchItem,
} from '@sb/design-system';
import type { WristCamera } from '@sb/integrations/camera/types';

interface CameraSettingsProps {
  value: WristCamera;
  onChange: (camera: WristCamera) => void;
  isDisabled?: boolean;
}

export function WristCameraSettings({
  value,
  onChange,
  isDisabled,
}: CameraSettingsProps) {
  return (
    <>
      <SettingsGroup className="tw-mb-16">
        <SettingsGroupSwitchItem
          label="Automatic white balance"
          checked={value.autoWhiteBalance}
          onChange={(e) =>
            onChange({ ...value, autoWhiteBalance: e.target.checked })
          }
          disabled={isDisabled}
        />

        <SettingsGroupSliderItem
          label="White balance"
          secondaryLabel={value.whiteBalance.toFixed(1)}
          min={2800}
          max={6500}
          step={10}
          value={value.whiteBalance}
          onChange={(e) =>
            onChange({ ...value, whiteBalance: e.target.valueAsNumber })
          }
          disabled={isDisabled || value.autoWhiteBalance}
        />
      </SettingsGroup>

      <SettingsGroup>
        <SettingsGroupSliderItem
          label="Brightness"
          secondaryLabel={value.brightness.toFixed(1)}
          min={-64}
          max={64}
          step={1}
          value={value.brightness}
          onChange={(e) =>
            onChange({ ...value, brightness: e.target.valueAsNumber })
          }
          disabled={isDisabled}
        />

        <SettingsGroupSliderItem
          label="Contrast"
          secondaryLabel={value.contrast.toFixed(1)}
          min={0}
          max={100}
          step={1}
          value={value.contrast}
          onChange={(e) =>
            onChange({ ...value, contrast: e.target.valueAsNumber })
          }
          disabled={isDisabled}
        />

        <SettingsGroupSliderItem
          label="Exposure"
          secondaryLabel={value.exposure.toFixed(1)}
          min={1}
          max={3000}
          step={1}
          value={value.exposure}
          onChange={(e) =>
            onChange({ ...value, exposure: e.target.valueAsNumber })
          }
          disabled={isDisabled}
        />

        <SettingsGroupSliderItem
          label="Sharpness"
          secondaryLabel={value.sharpness.toFixed(1)}
          min={0}
          max={100}
          step={1}
          value={value.sharpness}
          onChange={(e) =>
            onChange({ ...value, sharpness: e.target.valueAsNumber })
          }
          disabled={isDisabled}
        />

        <SettingsGroupSliderItem
          label="Hue"
          secondaryLabel={value.hue.toFixed(1)}
          min={-180}
          max={180}
          step={1}
          value={value.hue}
          onChange={(e) => onChange({ ...value, hue: e.target.valueAsNumber })}
          disabled={isDisabled}
        />
      </SettingsGroup>
    </>
  );
}
