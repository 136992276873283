import type { Integrations } from '@sb/remote-control/types';

export const getGripperImplementationDefaults = (): Pick<
  Integrations.IntegrationFrontend,
  'getIntegrationType' | 'canAddAsNewIntegration'
> => ({
  getIntegrationType: () => 'EndEffector',
  canAddAsNewIntegration: (existing) =>
    !existing.some(
      (otherItem) => otherItem.getIntegrationType() === 'EndEffector',
    ),
});
