import { Button, SettingsGroupItem } from '@sb/design-system';
import { useToast } from '@sbrc/hooks';

interface CaptureCalibrationButtonProps {
  isFound: boolean;
  isCapturing: boolean;
  setIsCapturing: (value: boolean) => void;
  onCapture: () => Promise<void>;
}

export function CaptureCalibrationButton({
  isFound,
  isCapturing,
  setIsCapturing,
  onCapture,
}: CaptureCalibrationButtonProps) {
  const { setToast } = useToast();

  return (
    <SettingsGroupItem className="tw-flex-none">
      <div className="tw-whitespace-normal tw-py-10 tw-flex-1">
        {isFound || isCapturing ? (
          <>
            <strong className="tw-font-medium">
              Calibration board found.{' '}
            </strong>
            Tap “Capture” to create a calibration entry.
          </>
        ) : (
          <>
            <strong className="tw-font-medium">
              Can’t see calibration board.{' '}
            </strong>
            Position the board so that all corners are visible.
          </>
        )}
      </div>
      <Button
        variant="Filled"
        size={32}
        className="tw-rounded-6"
        disabled={!isFound || isCapturing}
        onClick={async () => {
          setIsCapturing(true);

          try {
            await onCapture();
          } catch (error) {
            setToast({ kind: 'error', message: error?.message });
          }

          setIsCapturing(false);
        }}
      >
        {isCapturing ? 'Capturing…' : 'Capture'}
      </Button>
    </SettingsGroupItem>
  );
}
