import cx from 'classnames';

import ApplyChangesButton from './ApplyChangesButton';
import GripKind from './GripKind';
import GripperError from './GripperError';
import GripperForce from './GripperForce';
import GripperPayload from './GripperPayload';
import GripperWidth from './GripperWidth';
import type { DualValueModeProps } from './types';

export function GripperControlDualValueMode(props: DualValueModeProps) {
  return (
    <>
      <div
        className={cx(
          'tw-flex',
          'tw-flex-col',
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-gap-16',
          'tw-px-16',
        )}
      >
        <GripperError {...props} />
        <GripKind {...props} />
        <GripperPayload {...props} />
        <GripperForce {...props} />
        <GripperWidth {...props} />
        <div />
      </div>
      <ApplyChangesButton {...props} />
    </>
  );
}
