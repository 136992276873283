import { Switch } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import { Typography, Button, Layout } from '@sb/ui/components';
import { useCopy } from '@sb/ui/hooks';
import { SyncIcon, DuplicateIcon, CompletedIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';
import { useDevToken } from '@sbrc/services';

import styles from './DeveloperAPI.module.css';

type Props = {
  robot: Robot.ConvertedResponse;
};

export const DeveloperAPIModalBody = (props: Props) => {
  const { robot } = props;
  const [copyElURL, copyStringURL, blinkingURL] = useCopy();
  const [copyElLocalURL, copyStringLocalURL, blinkingLocalURL] = useCopy();
  const [copyElToken, copyStringToken, blinkingToken] = useCopy();
  const { token, regenerate, clearToken, loaded } = useDevToken();
  const enabled = token !== null;
  const apiURL = `http://${robot.lastKnownLocalIP ?? 'localhost'}:3000`;
  const remoteUrl = window.location.host;
  const isSbApp = remoteUrl.includes('sb.app');

  return (
    <div>
      {copyElURL}
      {copyElToken}
      {copyElLocalURL}
      <div className="tw-flex tw-flex-row tw-items-center tw-pb-24 tw-justify-between tw-border-b tw-border-divider-secondary">
        <div>
          <label htmlFor="enableDevAPIId" className="tw-block">
            Enable Developer API
          </label>
          <p className="tw-text-label-secondary tw-text-13 tw-mb-2">
            Control your Robot via the REST API and SDKs.
          </p>
          <p className="tw-text-label-secondary tw-text-13">
            Read the{' '}
            <a
              href="https://docs.standardbots.com"
              className="tw-underline hover:tw-text-orange"
            >
              Developer documentation
            </a>{' '}
            to find out more
          </p>
        </div>
        <Switch
          id="enableDevAPIId"
          checked={!!token}
          onChange={(e) => {
            if (e.target.checked) {
              regenerate();
            } else {
              clearToken();
            }
          }}
        />
      </div>
      <div className="tw-pt-16 tw-w-512">
        {(!loaded || !enabled) && (
          <div className="tw-text-24 tw-mx-auto tw-text-center tw-py-32 tw-mb-4 tw-text-label-tertiary">
            Enable API to view credentials
          </div>
        )}
        {loaded && enabled && (
          <div>
            <Typography component="h5">Local Robot Url</Typography>
            <Layout.Flex
              direction="horizontal"
              className={styles.informationRow}
            >
              <Layout.FlexChild grow={1} shrink={1}>
                <Typography color="gray">{apiURL}</Typography>
              </Layout.FlexChild>
              <Layout.FlexChild grow={0} shrink={0}>
                <Button
                  variant="link"
                  startIcon={
                    blinkingURL ? <CompletedIcon /> : <DuplicateIcon />
                  }
                  onClick={() => copyStringLocalURL(apiURL)}
                >
                  {blinkingLocalURL ? 'Copied' : 'Copy'}
                </Button>
              </Layout.FlexChild>
            </Layout.Flex>
            {isSbApp && (
              <>
                <Typography component="h5">Remote Robot Url</Typography>
                <Layout.Flex
                  direction="horizontal"
                  className={styles.informationRow}
                >
                  <Layout.FlexChild grow={1} shrink={1}>
                    <Typography color="gray">{remoteUrl}</Typography>
                  </Layout.FlexChild>
                  <Layout.FlexChild grow={0} shrink={0}>
                    <Button
                      variant="link"
                      startIcon={
                        blinkingURL ? <CompletedIcon /> : <DuplicateIcon />
                      }
                      onClick={() => copyStringURL(remoteUrl)}
                    >
                      {blinkingURL ? 'Copied' : 'Copy'}
                    </Button>
                  </Layout.FlexChild>
                </Layout.Flex>
              </>
            )}
            <Typography component="h5" className={margin.top.small}>
              Authorization Token
            </Typography>
            <Layout.Flex
              direction="horizontal"
              className={styles.informationRow}
            >
              <Layout.FlexChild grow={1} shrink={1}>
                <Typography color="gray">{token}</Typography>
              </Layout.FlexChild>
              <Layout.FlexChild grow={0} shrink={0}>
                <Button
                  variant="link"
                  onClick={() => regenerate()}
                  startIcon={<SyncIcon />}
                >
                  Regenerate
                </Button>
              </Layout.FlexChild>
              <Layout.FlexChild grow={0} shrink={0}>
                <Button
                  variant="link"
                  startIcon={
                    blinkingToken ? <CompletedIcon /> : <DuplicateIcon />
                  }
                  onClick={() => copyStringToken(token ?? '')}
                >
                  {blinkingToken ? 'Copied' : 'Copy'}
                </Button>
              </Layout.FlexChild>
            </Layout.Flex>
          </div>
        )}
      </div>
    </div>
  );
};
