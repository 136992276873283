import { OnRobotConnectionOptions } from '@sb/integrations/gripper-general/shared-components/OnRobotConnectionOptions';
import type { Integrations } from '@sb/remote-control/types';

import { ConfigureTCPTransform } from '../../frontend/ConfigureTCPTransform';

import { FingerGeometry } from './FingerGeometry';

export function OnRobot3FG15EquipmentManager({
  item,
  onUpdate,
  isFormDisabled,
  getEquipmentByKind,
  setIsFieldValid,
  isChildDevice,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'OnRobot3FG15') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  return (
    <>
      <FingerGeometry
        value={item.fingerConfiguration}
        onChange={(fingerConfiguration) =>
          onUpdate({ ...item, fingerConfiguration })
        }
        isDisabled={isFormDisabled}
      />

      <OnRobotConnectionOptions
        value={item.connectionOptions}
        onChange={(connectionOptions) =>
          onUpdate({ ...item, connectionOptions })
        }
        setIsValid={setIsFieldValid('connectionOptions')}
        isDisabled={isFormDisabled}
        isChildDevice={isChildDevice}
      />

      <ConfigureTCPTransform
        value={item.tcpTransform}
        onChange={(tcpTransform) => onUpdate({ ...item, tcpTransform })}
        defaultValue={
          getEquipmentByKind('OnRobot3FG15').getDefaultItem().tcpTransform
        }
        isDisabled={isFormDisabled}
      />
    </>
  );
}
