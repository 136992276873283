/* eslint-disable react/destructuring-assignment */

import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import type { Integrations } from '@sb/remote-control/types';
import { useRobotGripperState, useRobotPayloadState } from '@sbrc/hooks';

import { OnRobot3FG15WidgetInner } from './OnRobot3FG15WidgetInner';

export function OnRobot3FG15Widget(args: Integrations.WidgetProps) {
  const routineRunnerPayload = useRobotPayloadState({
    isVizbot: args.isVizbot,
  });

  const routineRunnerGripperState = useRobotGripperState(
    { isVizbot: args.isVizbot },
    'OnRobot3FG15',
  );

  const otherRobotGripperState = useRobotGripperState(
    { isVizbot: !args.isVizbot },
    'OnRobot3FG15',
  );

  if (
    !routineRunnerGripperState ||
    !routineRunnerGripperState.isConnected ||
    routineRunnerPayload === null
  ) {
    return (
      <GripperErrorWidget
        implementation={args.implementation}
        message="The gripper does not appear to be connected. Please ensure a 3FG15 gripper is properly mounted and connected."
      />
    );
  }

  if (!routineRunnerGripperState.isCalibrationOk) {
    return (
      <GripperErrorWidget
        implementation={args.implementation}
        message="Calibration is not OK. This also happens when the 2FG7 is attached instead of a 3FG15. If you're trying to use a 2FG7, go to the equipment tab, remove the 3FG15, and add the 2FG7."
      />
    );
  }

  return (
    <OnRobot3FG15WidgetInner
      routineRunnerPayload={routineRunnerPayload}
      routineRunnerGripperState={routineRunnerGripperState}
      otherRobotGripperState={otherRobotGripperState}
      args={args}
    />
  );
}
