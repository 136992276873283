import { useState } from 'react';

import { Modal } from '@sb/design-system';
import { useAuthentication } from '@sbrc/hooks';

import UnsavedChangesAlert from '../UnsavedChangesAlert';

import { UserSettingsForm } from './UserSettingsForm';

interface SafetySettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function UserSettingsModal({
  isOpen,
  onClose,
}: SafetySettingsModalProps) {
  const { settings } = useAuthentication();

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] =
    useState<boolean>(false);

  const handleClose = (forceClose = false) => {
    if (hasChanges && !forceClose) {
      setIsUnsavedChangesAlertOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => handleClose()}>
        {isOpen && settings && (
          <UserSettingsForm
            initialSettings={settings}
            onClose={handleClose}
            setHasChanges={setHasChanges}
          />
        )}
      </Modal>

      <UnsavedChangesAlert
        isOpen={isUnsavedChangesAlertOpen}
        onKeepEditing={() => setIsUnsavedChangesAlertOpen(false)}
        onDiscard={() => {
          setIsUnsavedChangesAlertOpen(false);
          setHasChanges(false);
          onClose();
        }}
      />
    </>
  );
}
