import { HoldableButton } from '@sb/design-system';
import { useGuidedMode, useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import type { DualValueModeProps } from './types';

export default function ApplyChangesButton({
  gripperControlState,
  isVizbot,
  dualChangerCommand,
}: DualValueModeProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const {
    canApplyGripperChanges,
    isGripWidthEqual,
    isDisabled,
    resetAll,
    targetPayload,
  } = gripperControlState;

  const onHoldApplyChangesButton = () => {
    runAdHocCommand({
      onRunCommand: async () => {
        const actuateGripper = await routineRunnerHandle.actuateDevice({
          command: dualChangerCommand ?? gripperControlState.command,
          payload: { mass: targetPayload },
        });

        return actuateGripper;
      },
      onError: (error) => {
        setToast({
          kind: 'error',
          message: `Error actuating gripper: ${error}`,
        });
      },
      onComplete: () => resetAll(),
    });
  };

  const action = isGripWidthEqual ? 'Tap' : 'Hold';

  return (
    <div className="tw-flex tw-flex-col">
      <hr className="tw-border-divider-primary" />

      <HoldableButton
        className="tw-rounded-6 tw-m-16"
        variant="Filled"
        onHold={onHoldApplyChangesButton}
        onRelease={stopGuidedMode}
        disabled={isDisabled || !canApplyGripperChanges}
        data-testid="gripper-widget-apply-changes-button"
      >
        {action} to Apply Changes
      </HoldableButton>
    </div>
  );
}
