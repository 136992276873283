import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import type { Integrations } from '@sb/remote-control/types/integrations';
import { useRobotGripperState } from '@sbrc/hooks';

import { WidgetControlBody } from './WidgetControlBody';

export function OnRobotVGP20Widget(props: Integrations.WidgetProps) {
  const { isVizbot, implementation, onCommandChange, defaultCommand } = props;

  const routineRunnerGripperState = useRobotGripperState(
    { isVizbot },
    'OnRobotVGP20',
  );

  if (!routineRunnerGripperState || !routineRunnerGripperState.isConnected) {
    return (
      <GripperErrorWidget
        implementation={implementation}
        message="The vacuum does not appear to be connected. Please ensure an OnRobot VGP20 Vacuum is properly mounted and connected."
      />
    );
  }

  return (
    <WidgetControlBody
      headerTitle={implementation.getDisplayName()}
      vacuumState={routineRunnerGripperState}
      isVizbot={isVizbot}
      onCommandChange={onCommandChange}
      defaultCommand={
        defaultCommand?.kind === 'OnRobotVGP20Command'
          ? defaultCommand
          : undefined
      }
    />
  );
}
