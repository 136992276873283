import cx from 'classnames';

import { Modal, NavigationBar, NavigationBarButton } from '@sb/design-system';
import type { FirmwareBuildData } from '@sbrc/hooks';
import { useFeatureFlag } from '@sbrc/hooks';

import { FirmwareMismatchPanel } from './FirmwareMismatchPanel';
import { FirmwareUpdateSection } from './FirmwareUpdateSection';
import { SoftwareUpdateActivePanel } from './SoftwareUpdateActivePanel';
import { SoftwareInstallErrorMessage } from './SoftwareUpdateErrorMessage';
import { SoftwareUpdateTargetPanel } from './SoftwareUpdateTargetPanel';
import type { SoftwareBuildData } from './useSoftwareBuildData';

interface SoftwareUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  buildData: SoftwareBuildData;
  firmwareBuildData: FirmwareBuildData;
}

export function SoftwareUpdateModal({
  onClose,
  isOpen,
  buildData,
  firmwareBuildData,
}: SoftwareUpdateModalProps) {
  const enableJointLevelFirmwareUpdates = useFeatureFlag(
    'enableJointLevelFirmwareUpdates',
  );

  const enableFirmwareMismatch = useFeatureFlag('enableFirmwareMismatch');

  const shouldDisplayFirmwareMismatch =
    enableFirmwareMismatch &&
    !buildData.isSoftwareUpdateAvailable &&
    !buildData.isInstallingSoftware;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={cx('tw-h-[620px]', 'tw-w-512', 'tw-flex', 'tw-flex-col')}
    >
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Cancel</NavigationBarButton>
        }
      >
        Software update
      </NavigationBar>

      <div
        className={cx(
          'tw-flex-1',
          'tw-px-24',
          'tw-overflow-auto',
          'tw-flex',
          'tw-flex-col',
          'tw-gap-16',
          'tw-mt-8',
          'tw-mb-20',
        )}
      >
        {shouldDisplayFirmwareMismatch && (
          <FirmwareMismatchPanel firmwareBuildData={firmwareBuildData} />
        )}

        <SoftwareUpdateTargetPanel buildData={buildData} />

        <SoftwareInstallErrorMessage
          lastErrorMessage={buildData.lastErrorMessage}
          isInstallingSoftware={buildData.isInstallingSoftware}
        />

        <SoftwareUpdateActivePanel buildData={buildData} />

        {enableJointLevelFirmwareUpdates && (
          <FirmwareUpdateSection buildData={buildData} />
        )}
      </div>
    </Modal>
  );
}
