/* eslint-disable react/destructuring-assignment */

import { NavigationBar } from '@sb/design-system';
import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import type { Integrations } from '@sb/remote-control/types';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';
import DisabledWidgetBody from '@sbrc/components/visualizer-view-shared/widget-panel/DisabledWidgetBody';
import { useRobotGripperState } from '@sbrc/hooks';

import { DualOnRobot2FG7Widget } from './DualOnRobot2FG7Widget';
import { DualOnRobot3FG15Widget } from './DualOnRobot3FG15Widget';
import { GripperSelect } from './GripperSelect';
import { useGripperControlState } from './useGripperControlState';

export function OnRobotDualQuickChangerWidget(args: Integrations.WidgetProps) {
  const {
    command: dualChangerCommand,
    changeActiveGripper,
    changeActiveGripperCommand,
  } = useGripperControlState({
    active:
      args.defaultCommand?.kind === 'OnRobotDualQuickChangerCommand'
        ? args.defaultCommand.active
        : undefined,
  });

  let dualChangerPrimary;
  let dualChangerSecondary;

  if (args.endEffector.kind === 'OnRobotDualQuickChanger') {
    dualChangerPrimary = args.endEffector.grippers.primary.kind;

    dualChangerSecondary = args.endEffector.grippers.secondary.kind;
  }

  const selectedGripper =
    dualChangerCommand.active === 'primary'
      ? dualChangerPrimary
      : dualChangerSecondary;

  const gripperDropdown = (
    <GripperSelect
      onGripperChange={changeActiveGripper}
      selectedGripper={dualChangerCommand.active}
      getEquipmentByKind={args.getEquipmentByKind}
      className="tw-mx-16 tw-mb-16"
    />
  );

  const routineRunnerDualChangerState = useRobotGripperState(
    { isVizbot: args.isVizbot },
    'OnRobotDualQuickChanger',
  );

  const otherRobotDualChangerState = useRobotGripperState(
    { isVizbot: !args.isVizbot },
    'OnRobotDualQuickChanger',
  );

  if (!routineRunnerDualChangerState) {
    return (
      <GripperErrorWidget
        implementation={args.implementation}
        message="The gripper does not appear to be connected. Please ensure a Dual Quick Changer is properly mounted and connected."
      />
    );
  }

  if (selectedGripper === 'OnRobot3FG15') {
    return (
      <DualOnRobot3FG15Widget
        {...args}
        routineRunnerDualChangerState={routineRunnerDualChangerState}
        otherRobotDualChangerState={otherRobotDualChangerState}
        additionalControls={gripperDropdown}
        dualChangerCommand={dualChangerCommand}
        changeActiveGripperCommand={changeActiveGripperCommand}
      />
    );
  }

  if (selectedGripper === 'OnRobot2FG7') {
    return (
      <DualOnRobot2FG7Widget
        {...args}
        routineRunnerDualChangerState={routineRunnerDualChangerState}
        otherRobotDualChangerState={otherRobotDualChangerState}
        additionalControls={gripperDropdown}
        dualChangerCommand={dualChangerCommand}
        changeActiveGripperCommand={changeActiveGripperCommand}
      />
    );
  }

  return (
    <WidgetView data-testid="no-gripper-control-widget">
      <NavigationBar>{args.implementation.getDisplayName()}</NavigationBar>
      {gripperDropdown}
      <DisabledWidgetBody
        contentHeader="No gripper configured"
        content="Configure a gripper in the equipment manager"
        className="tw-mt-32"
      />
    </WidgetView>
  );
}
