import cx from 'classnames';

import { Button, Icon } from '@sb/design-system';

interface RotationOverlayProps {
  /* rotation in quarter-turns clockwise */
  rotation: number;
  setRotation: (value: number) => void;
}

export function RotationOverlay({
  rotation,
  setRotation,
}: RotationOverlayProps) {
  return (
    <div
      className={cx(
        'tw-w-128',
        'tw-flex',
        'tw-absolute',
        'tw-justify-between',
        'tw-bg-metal-30',
        'dark:tw-bg-metal-80',
        'tw-rounded-full',
        'tw-left-0',
        'tw-right-0',
        'tw-m-auto',
        'tw-bottom-16',
        'tw-items-center',
      )}
    >
      <Button
        onClick={() => setRotation((rotation + 3) % 4)}
        variant="Elevated"
      >
        <Icon kind="arrowAnticlockwise" />
      </Button>

      <span>{rotation * 90}°</span>

      <Button
        onClick={() => setRotation((rotation + 1) % 4)}
        variant="Elevated"
      >
        <Icon kind="arrowClockwise" />
      </Button>
    </div>
  );
}
