import type { Placement } from '@popperjs/core';
import cx from 'classnames';

import { Icon, Tooltip } from '@sb/design-system';

interface HelpPopoverProps {
  className?: string;
  message: React.ReactNode;
  placement?: Placement;
}

const HelpPopover = ({ className, message, placement }: HelpPopoverProps) => {
  return (
    <Tooltip
      className={cx('tw-flex-none', className)}
      placement={placement}
      content={message}
      delayMS={5}
      onClick={(e) => {
        // when popover is in a <label>, avoid clicks from toggling switch etc.
        e.preventDefault();
      }}
    >
      <Icon
        kind="questionmarkCircle"
        className="tw-text-label-tertiary tw-icon-22 tw-cursor-pointer"
      />
    </Tooltip>
  );
};

export default HelpPopover;
