import cx from 'classnames';
import { useState } from 'react';

import {
  Button,
  Icon,
  NavigationBar,
  NavigationBarButton,
  Popper,
} from '@sb/design-system';

type EmptyStateProps = {
  onClose: () => void;
  newEquipmentPicker: React.ReactNode;
};

export function EmptyState({ onClose, newEquipmentPicker }: EmptyStateProps) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <>
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Done</NavigationBarButton>
        }
      >
        Equipment
      </NavigationBar>
      <div
        className={cx(
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-flex',
          'tw-flex-col',
          'tw-items-center',
          'tw-justify-center',
        )}
      >
        <Icon
          kind="squareGrid2x2"
          className={cx(
            'tw-flex-none',
            'tw-icon-42',
            'tw-text-label-secondary',
            'tw-mx-auto',
          )}
        />
        <h3 className={cx('tw-heading-44', 'tw-flex-none')}>
          No equipment configured
        </h3>
        <p
          className={cx(
            'tw-flex-none',
            'tw-text-15',
            'tw-text-label-tertiary',
            'tw-mx-32',
            'tw-mb-16',
            'tw-text-center',
            'tw-max-w-320',
          )}
        >
          Manage the grippers, end effectors, and network devices that you use
          with your robot here.
        </p>

        <Popper
          isOpen={isPickerOpen}
          onClose={() => setIsPickerOpen(false)}
          content={newEquipmentPicker}
          placement="top"
        >
          <Button
            variant="Filled"
            onClick={() => setIsPickerOpen(!isPickerOpen)}
            className="tw-rounded-10"
          >
            <Icon kind="plus" />
            <span>Add Equipment</span>
          </Button>
        </Popper>
      </div>
    </>
  );
}
