import { useRouter } from 'next/router';

import { SegmentedButton } from '@sb/design-system';
import { LINK_TO_MOVE_PAGE, LINK_TO_ROUTINE_LIST_PAGE } from '@sbrc/utils';

export type Page = 'moveRobot' | 'routines' | 'robotList';

interface AppHeaderNavProps {
  activePage?: Page;
}

export default function AppHeaderNav({ activePage }: AppHeaderNavProps) {
  const { push } = useRouter();

  return (
    <nav className="tw-flex tw-flex-none">
      <SegmentedButton
        className="tw-flex-none"
        size={36}
        variant="Gray"
        checked={activePage === 'moveRobot'}
        onChange={() => {}}
        onClick={() => push(LINK_TO_MOVE_PAGE)}
        data-testid="app-header-nav-move-robot-link"
      >
        Move Robot
      </SegmentedButton>
      <SegmentedButton
        className="tw-flex-none"
        size={36}
        variant="Gray"
        checked={activePage === 'routines'}
        onChange={() => {}}
        onClick={() => push(LINK_TO_ROUTINE_LIST_PAGE)}
        data-testid="app-header-nav-routines-link"
      >
        Routines
      </SegmentedButton>
    </nav>
  );
}
