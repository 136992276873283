import type { Integrations } from '@sb/remote-control/types';

import { InvalidConfigurationEquipmentManager } from './equipment-manager/InvalidConfigurationEquipmentManager';
import { InvalidConfiguration } from './types';

export const invalidConfigurationImplementation: Integrations.IntegrationFrontend<'InvalidConfiguration'> =
  {
    getIntegrationType: () => 'Other',
    canAddAsNewIntegration: () => false,
    iconKind: 'circleSlash',
    getSupportedSteps: () => [],
    getManufacturerName: () => 'Invalid Configuration',
    getShortName: () => 'Invalid Configuration',
    getDisplayName: () => {
      return 'Invalid Configuration';
    },
    getDefaultItem: () =>
      InvalidConfiguration.parse({ kind: 'InvalidConfiguration' }),
    EquipmentManagerEditForm: InvalidConfigurationEquipmentManager,
    getIntegrationVariables: () => [],
  };
