import { GaugeStepperDualValue } from '@sb/design-system';
import {
  PAYLOAD_MASS_KG_ABSOLUTE_MAX,
  PAYLOAD_MASS_KG_ABSOLUTE_MIN,
  PAYLOAD_MASS_KG_DEFAULT,
} from '@sb/routine-runner';
import { massToString } from '@sb/utilities';
import { useRobotPayloadState } from '@sbrc/hooks';
import { PAYLOAD_KG_SLIDER_STEP } from '@sbrc/utils';

import type { DualValueModeProps } from './types';

export default function GripperPayload({
  gripperControlState,
  isVizbot,
}: DualValueModeProps) {
  const routineRunnerPayload = useRobotPayloadState({ isVizbot });
  const currentPayload = routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT;

  const { isDisabled, setTargetPayload, targetPayload, isPayloadEqual } =
    gripperControlState;

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Payload</h5>
      <GaugeStepperDualValue
        value={currentPayload}
        targetValue={isPayloadEqual ? undefined : targetPayload}
        isDisabled={isDisabled}
        onChange={setTargetPayload}
        min={PAYLOAD_MASS_KG_ABSOLUTE_MIN}
        max={PAYLOAD_MASS_KG_ABSOLUTE_MAX}
        step={PAYLOAD_KG_SLIDER_STEP}
        valueToString={massToString}
      />
    </section>
  );
}
