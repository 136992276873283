import { GaugeStepperDualValue } from '@sb/design-system';
import { forceToString } from '@sb/utilities';

import { OR_2FG7_FORCE_NEWTONS_SLIDER_STEP } from '../../constants';

import type { DualValueModeProps } from './types';

export default function GripperForce({
  gripperControlState,
}: DualValueModeProps) {
  const {
    command,
    forceRange,
    setTargetForce,
    currentForce,
    isForceEqual,
    isDisabled,
  } = gripperControlState;

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Force</h5>
      <GaugeStepperDualValue
        kind="Filled"
        value={currentForce}
        targetValue={isForceEqual ? undefined : command.targetForce}
        isDisabled={isDisabled}
        onChange={setTargetForce}
        min={forceRange.min}
        max={forceRange.max}
        step={OR_2FG7_FORCE_NEWTONS_SLIDER_STEP}
        valueToString={forceToString}
      />
    </section>
  );
}
