import { GaugeStepperDualValue } from '@sb/design-system';
import {
  PAYLOAD_MASS_KG_ABSOLUTE_MAX,
  PAYLOAD_MASS_KG_ABSOLUTE_MIN,
} from '@sb/routine-runner';
import { massToString } from '@sb/utilities';
import { PAYLOAD_KG_SLIDER_STEP } from '@sbrc/utils';

import type { DualValueModeProps } from './types';

export default function GripperPayload({
  gripperControlState,
}: DualValueModeProps) {
  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Payload</h5>
      <GaugeStepperDualValue
        value={gripperControlState.currentPayload}
        targetValue={
          gripperControlState.isPayloadEqual
            ? undefined
            : gripperControlState.targetPayload
        }
        onChange={gripperControlState.setTargetPayload}
        max={PAYLOAD_MASS_KG_ABSOLUTE_MAX}
        min={PAYLOAD_MASS_KG_ABSOLUTE_MIN}
        step={PAYLOAD_KG_SLIDER_STEP}
        valueToString={massToString}
      />
    </section>
  );
}
