import { clamp } from 'lodash';
import { useMemo, useState } from 'react';

import { calculateOR2FG7WidthRangeMetersFromRoutineRunnerState } from '@sb/integrations/OnRobot2FG7';
import { PAYLOAD_MASS_KG_DEFAULT } from '@sb/routine-runner';
import { isApproximatelyEqual } from '@sb/utilities';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotStateKind,
} from '@sbrc/hooks';

import {
  type OR2FG7GripKind,
  FORCE_RANGE,
  OR2FG7_MAX_SPEED,
  OR_2FG7_DIAMETER_METERS_SLIDER_STEP,
  OR_2FG7_FORCE_NEWTONS_SLIDER_STEP,
  OR_2FG7_GRIP_KINDS_DEFAULT,
} from '../../constants';
import type { OnRobot2FG7Command, OnRobot2FG7State } from '../../types';
import { getOR2FG7ActiveWidth } from '../../util';

type GripperRange = {
  min: number;
  max: number;
};

type GripperControlStateOutput = {
  setGripKind: (gripKind: OR2FG7GripKind) => void;

  gripWidthRange: GripperRange;
  currentGripWidth: number;
  setTargetGripWidth: (gripWidth: number) => void;
  isGripWidthEqual: boolean;

  forceRange: GripperRange;
  currentForce: number;
  setTargetForce: (targetForce: number) => void;
  isForceEqual: boolean;

  currentPayload: number;
  setTargetPayload: (targetPayload: number) => void;
  targetPayload: number;
  isPayloadEqual: boolean;

  canApplyGripperChanges: boolean;
  command: OnRobot2FG7Command;
  resetAll: () => void;
  error?: string;
  isConnected: boolean;
  isDisabled: boolean;
  linearSensorError: boolean;
  uncalibratedError: boolean;
};

interface UseGripperControlStateArguments {
  isVizbot: boolean;
  routineRunnerGripperState: OnRobot2FG7State;
  routineRunnerPayload: number | null;
}

export function useGripperControlState({
  isVizbot,
  routineRunnerPayload,
  routineRunnerGripperState,
}: UseGripperControlStateArguments): GripperControlStateOutput {
  const currentGripKind =
    routineRunnerGripperState.gripKind ?? OR_2FG7_GRIP_KINDS_DEFAULT;

  const [gripKind = currentGripKind, setGripKind] = useState<
    OR2FG7GripKind | undefined
  >();

  /* grip width */

  const gripWidthRange: GripperRange =
    calculateOR2FG7WidthRangeMetersFromRoutineRunnerState(
      routineRunnerGripperState,
      gripKind,
    );

  const currentGripWidth =
    currentGripKind === gripKind
      ? getOR2FG7ActiveWidth(routineRunnerGripperState)
      : NaN; // width in gripper state is not relevant when switching grip kind

  const [
    targetGripWidth = getOR2FG7ActiveWidth(routineRunnerGripperState),
    setTargetGripWidth,
  ] = useState<number | undefined>();

  const isGripWidthEqual = isApproximatelyEqual(
    currentGripWidth,
    targetGripWidth,
    OR_2FG7_DIAMETER_METERS_SLIDER_STEP,
  );

  /* force */

  const currentForce = routineRunnerGripperState.force;

  const [targetForce = currentForce, setTargetForce] = useState<
    number | undefined
  >();

  const isForceEqual = isApproximatelyEqual(
    currentForce,
    targetForce,
    OR_2FG7_FORCE_NEWTONS_SLIDER_STEP,
  );

  /* payload */

  const currentPayload = routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT;

  const [targetPayload = currentPayload, setTargetPayload] = useState<
    number | undefined
  >();

  const isPayloadEqual = currentPayload === targetPayload;

  /* other */

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    isVizbot,
  });

  const isRoutineRunning = useRobotStateKind({ isVizbot }) === 'RoutineRunning';

  const canApplyGripperChanges =
    !isRoutineRunning &&
    !isAnotherSessionMovingRobot &&
    (!isGripWidthEqual ||
      !isForceEqual ||
      gripKind !== currentGripKind ||
      routineRunnerPayload !== targetPayload);

  const command = useMemo<OnRobot2FG7Command>(
    () => ({
      kind: 'OnRobot2FG7Command',
      gripKind,
      targetDiameter: clamp(
        targetGripWidth,
        gripWidthRange.min,
        gripWidthRange.max,
      ),
      targetSpeed: OR2FG7_MAX_SPEED,
      targetForce: clamp(targetForce, FORCE_RANGE.min, FORCE_RANGE.max),
    }),
    [
      gripKind,
      gripWidthRange.min,
      gripWidthRange.max,
      targetForce,
      targetGripWidth,
    ],
  );

  const resetAll = () => {
    setGripKind(undefined);
    setTargetGripWidth(undefined);
    setTargetForce(undefined);
    setTargetPayload(undefined);
  };

  const { linearSensorError, uncalibratedError } = routineRunnerGripperState;

  const isDisabled =
    !routineRunnerGripperState.isConnected ||
    Boolean(routineRunnerGripperState.error) ||
    linearSensorError ||
    uncalibratedError;

  return {
    setGripKind,

    gripWidthRange,
    currentGripWidth,
    setTargetGripWidth,
    isGripWidthEqual,

    forceRange: FORCE_RANGE,
    currentForce,
    setTargetForce,
    isForceEqual,

    currentPayload,
    setTargetPayload,
    targetPayload,
    isPayloadEqual,

    canApplyGripperChanges,
    command,
    resetAll,
    error: routineRunnerGripperState.error,
    isConnected: routineRunnerGripperState.isConnected,
    isDisabled,
    linearSensorError,
    uncalibratedError,
  };
}
