import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import { get3FG15State } from '@sb/integrations/OnRobot3FG15/utils';
import type { Integrations } from '@sb/remote-control/types';
import { useRobotPayloadState } from '@sbrc/hooks';

import type {
  OnRobotDualQuickChangerCompatibleCommand,
  OnRobotDualQuickChangerCommand,
  OnRobotDualQuickChangerState,
} from '../types';

import { DualOnRobot3FG15WidgetInner } from './DualOnRobot3FG15WidgetInner';

interface DualOnRobot3FG15WidgetProps extends Integrations.WidgetProps {
  additionalControls?: React.ReactNode;
  routineRunnerDualChangerState: OnRobotDualQuickChangerState;
  otherRobotDualChangerState: OnRobotDualQuickChangerState | null;
  dualChangerCommand: OnRobotDualQuickChangerCommand;
  changeActiveGripperCommand: (
    action: OnRobotDualQuickChangerCompatibleCommand,
  ) => void;
}

export function DualOnRobot3FG15Widget({
  isVizbot,
  onCommandChange,
  robot,
  additionalControls,
  routineRunnerDualChangerState,
  otherRobotDualChangerState,
  dualChangerCommand,
  changeActiveGripperCommand,
  getEquipmentByKind,
  implementation,
}: DualOnRobot3FG15WidgetProps) {
  const routineRunnerGripperState = get3FG15State(
    routineRunnerDualChangerState[dualChangerCommand.active],
  );

  const otherRobotGripperState = get3FG15State(
    otherRobotDualChangerState?.[dualChangerCommand.active],
  );

  const routineRunnerPayload = useRobotPayloadState({ isVizbot });

  if (
    !routineRunnerGripperState ||
    routineRunnerPayload === null ||
    !routineRunnerGripperState.isConnected
  ) {
    return (
      <GripperErrorWidget
        implementation={implementation}
        additionalControls={additionalControls}
        message="The gripper does not appear to be connected. Please ensure a 3FG15 gripper is properly mounted and connected."
      />
    );
  }

  if (!routineRunnerGripperState.isCalibrationOk) {
    return (
      <GripperErrorWidget
        implementation={implementation}
        additionalControls={additionalControls}
        message="Calibration Error. This occurs when the gripper is misconfigured, or a 2FG7 is attached instead of a 3FG15. If you're trying to use a 2FG7, go to the equipment tab, remove the 3FG15, and add the 2FG7."
      />
    );
  }

  return (
    <DualOnRobot3FG15WidgetInner
      {...{
        routineRunnerPayload,
        otherRobotGripperState,
        routineRunnerGripperState,
        additionalControls,
        robot,
        isVizbot,
        onCommandChange,
        dualChangerCommand,
        changeActiveGripperCommand,
        getEquipmentByKind,
      }}
    />
  );
}
