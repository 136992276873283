import { Menu, MenuItem, RichSelect } from '@sb/design-system';
import type { DistanceUnit } from '@sb/remote-control/types';
import {
  DISTANCE_UNIT_INFOS,
  getDistanceUnitInfo,
} from '@sb/remote-control/util/distance';
import type { SelectProps } from '@sb/ui/components';

interface SelectDistanceUnitProps extends Omit<SelectProps, 'children'> {
  value: DistanceUnit;
  onSelect: (value: DistanceUnit) => void;
  isDisabled?: boolean;
}

export function SelectDistanceUnit({
  value,
  onSelect,
  isDisabled,
}: SelectDistanceUnitProps) {
  return (
    <div>
      <RichSelect
        className="tw-text-label-secondary"
        disabled={isDisabled}
        options={
          <Menu>
            {DISTANCE_UNIT_INFOS.map((info) => (
              <MenuItem
                key={info.unit}
                onClick={() => onSelect(info.unit)}
                secondaryIconKind={info.unit === value ? 'checkmark' : 'blank'}
              >
                {info.name}
              </MenuItem>
            ))}
          </Menu>
        }
      >
        {getDistanceUnitInfo(value).name}
      </RichSelect>
    </div>
  );
}
