import { GaugeStepperSingleValue } from '@sb/design-system';
import {
  FORCE_RANGE,
  targetForceStringConvert,
} from '@sb/integrations/OnRobotScrewdriver';

import type { DualValueModeProps } from './types';

export default function GripperTargetForce({
  gripperControlState,
}: DualValueModeProps) {
  const { changeTargetForce, target } = gripperControlState;

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-flex tw-pl-16">Z axis force</h5>

      <GaugeStepperSingleValue
        value={target.targetForce}
        onChange={changeTargetForce}
        {...FORCE_RANGE}
        valueToString={targetForceStringConvert}
      />
    </section>
  );
}
